import * as Yup from "yup";

export const LoginValidationSchema = () => {
  return Yup.object({
      email: Yup.string().email('Votre adresse email est invalide')
          .required('Email requis'),
      password: Yup.string()
          .required('Mot de passe requis')
          .min(8, 'Le mot de passe doit contenir au minimum 8 caractère.')
  });
};
