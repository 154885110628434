import { useFormik } from "formik";
import {useEffect, useState} from "react";
import { Account } from "./Account";
import { DevisVoyage } from "./DevisVoyage";
import { devisValidationSchema } from "./_const";
import { optionsTrip } from "./_const";
import { useSearchParams } from "react-router-dom";
import {Loader} from "../../../../components/Loader";
import commandService from "../../../../_services/command.service";

export const StepsForm = () => {
  const [queryParameters] = useSearchParams();
  const optionParams = queryParameters.get("option");
  const nbPersonneParams = queryParameters.get("personne");
  const startParams = queryParameters.get("depart");
  const endParams = queryParameters.get("retour");
  const [ prices, setPrice ] = useState([]);
  const [ loading, setLoading ] = useState(true);
  const [steps, setSteps] = useState(1);

  useEffect(() => {
    commandService.getAllPrice().then(r => {
      setPrice(r.data);
      setLoading(false)
    }).catch(err => console.error(err))
  }, []);

  const onSubmitAction = () => {
    switch (steps) {
      case 1:
        setSteps(2);
        break;
      case 2:
      default:
        break;
    }
  };
  const goodOption = (nbrOption) => {
    if(parseInt(nbrOption) === 0 && parseInt(nbrOption) >= 6){ return []; }
    let result = []
    let choiceOption = parseInt(nbrOption) + 1;
    optionsTrip.forEach(el => {
      if(el.value === choiceOption){
        if(choiceOption === 5){
          result = el.name.split(",");
        }else{
          result.push(el.name);
        }
      }
    });
    return result;
  }

  const formik= useFormik({
    initialValues: {
      totalPersonne: (nbPersonneParams === null) ? "1" : nbPersonneParams,
      totalPersonneAges: "0",
      startDate: startParams,
      endDate: endParams,
      option: goodOption(optionParams),
     },
    onSubmit: onSubmitAction,
    validationSchema: devisValidationSchema,
  });

  switch (steps) {
    case 1:
      return <>
        <div className="p-6 w-full text-center">
          <p className="text-xl font-bold">Votre devis d'assurance Schengen</p>
          <p className="my-2">
            Dites nous en plus sur votre voyage à venir dans l'espace Schengen :
          </p>
        </div>
        <div className="p-12 w-full flex justify-around items-center">
          <div className="flex">
            {loading ? <Loader /> : <DevisVoyage formik={formik} prices={prices} />}
          </div>
        </div>
      </>;
    case 2:
      return <Account formik={formik} />;
    default:
      break;
  }
};
