import React from "react";
import styles from "./style.module.scss";

export const Banner = () => {
    return (
        <div className={`${styles.bgBlock} w-full h-[235px] flex justify-around items-center`}>
            <div className="w-[1000px] text-center">
                <p className="text-3xl font-bold mb-6">
                    Espace Schengen et zone Schengen
                </p>
                <p className="text-sm">
                    Un peu de culture générale pour mieux comprendre le contexte dans lequel nous proposons ce type de contrat d'assurance voyage dans Schengen.
                </p>
            </div>
        </div>
    );
};
