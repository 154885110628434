import React from "react";
import Sidebar from "../../components/Sidebar";
import Dashboardview from "../../components/Dashboardview";
import {Outlet} from "react-router-dom";

export const DashboardLayout = (props) => {
    const {name} = props
    return (
            <div className="flex ">
                <div className="basis-[12%] h-[100vh]">
                    <Sidebar />
                </div>
                <div className="basis-[88%] border h-[100vh]">
                    <Dashboardview name={name}/>
                    <div>
                        <Outlet></Outlet>
                    </div>
                </div>
            </div>
    );
};
