import Axios from './caller.service'

/**
 * Récupération de la liste des command
 * @returns {Promise}
 */
let getAllCoommands = () => {
    return Axios.get('/command')
}

/**
 * Récupération de command
 * @param {number} id 
 * @returns {Promise}
 */
let getAllPrice = () => {
    return Axios.get('/price/')
}

/**
 * Récupération de command avec Id user
 * @param {number} id 
 * @returns {Promise}
 */
let getCommands = (id) => {
    return Axios.get('/process/'+ id)
}

/**
 * Récupération de command avec un id 
 * @param {number} id 
 * @returns {Promise}
 */
let getCommandById = async (id) => {
    const response = await Axios.get('/command/'+ id);
    if(response.status === 200){
        const mappedObject = response.data.map(item => ({
            active: item.active,
            addressReside: item.address_reside,
            amount: parseFloat(item.amount), // On convertit amount en nombre
            born: new Date(item.born), // Conversion en objet Date
            commandId: item.command_id,
            contractType: item.contract_type,
            countryReside: item.country_reside,
            createdAt: new Date(item.created_at), // Conversion en objet Date
            dateEnd: new Date(item.date_end), // Conversion en objet Date
            dateStart: new Date(item.date_start), // Conversion en objet Date
            destination: item.destination,
            email: item.email,
            expirPass: new Date(item.expir_pass), // Conversion en objet Date
            firstname: item.firstname,
            gender: item.gender,
            id: item.id,
            lastname: item.lastname,
            moreFifteenDays: item.more_fifteen_days,
            multiTrip: item.multi_trip,
            nationality: item.nationality,
            noFranchise: item.no_franchise,
            numberPass: item.number_pass,
            objectifTrip: item.objectif_trip,
            price: parseFloat(item.price), // On convertit price en nombre
            profession: item.profession,
            repaymentVisa: item.repayment_visa,
            responsabilityCivil: item.responsability_civil,
            rgpd: item.rgpd,
            speakCertif: item.speak_certif,
            speaking: item.speaking,
            updatedAt: new Date(item.updated_at) // Conversion en objet Date
        }));

        console.log(mappedObject);
        return mappedObject;
    }else{
        // navigate("/dashboard/commande/recapitulatif/" + r.data.id);
        return [{}];
    }
}

/**
 * Ajout d'une command
 * @param {object} command 
 * @returns {Promise}
 */
let addCommand = (command) => {
    return Axios.post('/process/', command)
}

/**
 * Paiement d'une command
 * @param {String} commandId 
 * @returns {Promise}
 */
let paimentCommand = (commandId) => {
    return Axios.get('/process/paiement/' + commandId);
}

/**
 * Détail de paiement d'une command
 * @param {String} token 
 * @returns {Promise}
 */
let paymentDetailsCommand = (token) => {
    return Axios.get('/transaction/paiement/' + token);
}

/**
 * Mise à jour d'un Command
 * @param {object} command 
 * @returns {Promise}
 */
let updateCommand = (command) => {
    return Axios.patch('/command/'+command.id, command)
}

let getCommandsValid = (id) => {
    return Axios.get('/command/valid/'+ id);
}

/**
 * Suppression d'un command
 * @param {number} id 
 * @returns {Promise}
 */
let deleteCommand = (id) => {
    return Axios.delete('/command/'+ id)
}

const commandService = {
    getAllPrice, getAllCoommands, getCommands, addCommand, paimentCommand, paymentDetailsCommand, updateCommand, getCommandById, getCommandsValid, deleteCommand
}

// Déclaration des services pour import
export default commandService;