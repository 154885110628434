import React from "react";
import { CardDevis } from "./CardDevis";
import { Price } from "./Price";
import styles from "./style.module.scss";

export const FirstBanner = () => {
  return (
    <div className={`${styles.flagBg} w-full h-[400px] flex justify-around items-center`}>
      <Price />
      <CardDevis />
    </div>
  );
};
