import accountService from '../../../../_services/account.service';
import {registrationValidationSchema} from "./_const";
import { ScInput } from "../../../../components/ScInput";
import { Modal } from "../../../../components/Modal";
import {useNavigate} from "react-router-dom";
import { useState } from "react";
import {useFormik} from "formik";
import _ from "lodash";
import { Loader } from '../../../../components/Loader';


export const RegistrationForm = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            gender: 'male',
            lastname: '',
            firstname: '',
            email: '',
            born: new Date(),
            phone: '',
            occupation: '',
            nationality: '',
            password: '',
            confirmPassword: '',
            countryResidence: '',
            addressResidence: '',
            numberPassport: '',
            expirationPassport: new Date(),
            acquainted: '',
            other: '',
            condition: false
        },
        onSubmit: (values) => {
            setLoading(true)
            accountService.register(values).then((r) => {
                if (r.status === 200) {
                    setLoading(false);
                    setMessage(r.data);
                    setModalOpen(true);
                }
            }).catch((e) => {
                console.log(e, " <= error ")
                if (e.response.data.message !== undefined) {
                    console.log(e.response.data.message);
                }
            });
        },
        validationSchema: registrationValidationSchema,
    });

    const handleButtonClick = () => {
        setModalOpen(false);
        navigate("/auth");
    };

    return (
        <form className="px-48 py-24">
            <div className="border-b border-gray-900/10 pb-12 border shadow rounded-lg p-5">
                <div>
                    <p className="text-xl font-bold">Création de compte client</p>
                    <p className="my-2">
                        Veuillez saisir ces informations afin de creer votre compte client :
                    </p>
                </div>
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-start-1 col-end-1">
                        <label htmlFor="gender" className="block text-sm font-medium leading-6 text-gray-900">
                            Genre
                        </label>
                        <div className="mt-2">
                            <select name="gender"
                                onChange={formik.handleChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <option value="male">Monsieur</option>
                                <option value="female">Madame</option>
                            </select>
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="lastname" className="block text-sm font-medium leading-6 text-gray-900">
                            Nom
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="lastname"
                                id="lastname"
                                autoComplete="given-name"
                                onChange={formik.handleChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                        {formik.touched.lastname && formik.errors.lastname ? (
                            <div className="text-red-700">{formik.errors.lastname}</div>
                        ) : null}
                    </div>

                    <div className="sm:col-span-3">
                        <label htmlFor="firstname" className="block text-sm font-medium leading-6 text-gray-900">
                            Prénom
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="firstname"
                                id="firstname"
                                autoComplete="given-name"
                                onChange={formik.handleChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                        {formik.touched.firstname && formik.errors.firstname ? (
                            <div className="text-red-700">{formik.errors.firstname}</div>
                        ) : null}
                    </div>

                    <div className="sm:col-span-3">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Adresse email
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="email"
                                id="email"
                                autoComplete="given-name"
                                onChange={formik.handleChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                        {formik.touched.email && formik.errors.email ? (
                            <div className="text-red-700">{formik.errors.email}</div>
                        ) : null}
                    </div>
                    <div className="sm:col-span-3">
                        <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                            Téléphone
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="phone"
                                id="phone"
                                autoComplete="family-name"
                                onChange={formik.handleChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                        {formik.touched.phone && formik.errors.phone ? (
                            <div className="text-red-700">{formik.errors.phone}</div>
                        ) : null}
                    </div>
                    <div className="sm:col-span-3">
                        <label htmlFor="occupation" className="block text-sm font-medium leading-6 text-gray-900">
                            Profession
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="occupation"
                                id="occupation"
                                autoComplete="family-name"
                                onChange={formik.handleChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                        {formik.touched.occupation && formik.errors.occupation ? (
                            <div className="text-red-700">{formik.errors.occupation}</div>
                        ) : null}
                    </div>
                    <div className="sm:col-span-3">
                        <label htmlFor="acquainted" className="block text-sm font-medium leading-6 text-gray-900">
                            Comment nous avez vous connu ?
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="acquainted"
                                id="acquainted"
                                autoComplete="family-name"
                                onChange={formik.handleChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                        {formik.touched.acquainted && formik.errors.acquainted ? (
                            <div className="text-red-700">{formik.errors.acquainted}</div>
                        ) : null}
                    </div>
                    <div className="sm:col-span-3">
                        <ScInput
                            name="born"
                            onChange={formik.handleChange}
                            label=" Date de naissance :"
                            type="date"
                            position="right"
                        />
                        {formik.touched.born && formik.errors.born ? (
                            <div className="text-red-700">{formik.errors.born}</div>
                        ) : null}
                    </div>
                    <div className="sm:col-span-3">
                        <label htmlFor="other" className="block text-sm font-medium leading-6 text-gray-900">
                            Si autre, pouvez vous préciser ?
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="other"
                                id="other"
                                autoComplete="family-name"
                                onChange={formik.handleChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                        {formik.touched.other && formik.errors.other ? (
                            <div className="text-red-700">{formik.errors.other}</div>
                        ) : null}
                    </div>
                    <div className="sm:col-span-3">
                        <label htmlFor="nationality" className="block text-sm font-medium leading-6 text-gray-900">
                            Pays de nationalité :
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="nationality"
                                id="nationality"
                                autoComplete="given-name"
                                onChange={formik.handleChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                        {formik.touched.nationality && formik.errors.nationality ? (
                            <div className="text-red-700">{formik.errors.nationality}</div>
                        ) : null}
                    </div>

                    <div className="sm:col-span-3">
                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                            Mot de passe :
                        </label>
                        <div className="mt-2">
                            <input
                                type="password"
                                name="password"
                                id="password"
                                autoComplete="family-name"
                                onChange={formik.handleChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                        {formik.touched.password && formik.errors.password ? (
                            <div className="text-red-700">{formik.errors.password}</div>
                        ) : null}
                    </div>
                    <div className="sm:col-span-3">
                        <label htmlFor="countryResidence" className="block text-sm font-medium leading-6 text-gray-900">
                            Pays de residence :
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="countryResidence"
                                id="countryResidence"
                                autoComplete="given-name"
                                onChange={formik.handleChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                        {formik.touched.countryResidence && formik.errors.countryResidence ? (
                            <div className="text-red-700">{formik.errors.countryResidence}</div>
                        ) : null}
                    </div>

                    <div className="sm:col-span-3">
                        <label htmlFor="confirmPassword" className="block text-sm font-medium leading-6 text-gray-900">
                            Confirmez votre mot de passe :
                        </label>
                        <div className="mt-2">
                            <input
                                type="password"
                                name="confirmPassword"
                                id="confirmPassword"
                                autoComplete="family-name"
                                onChange={formik.handleChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                        {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                            <div className="text-red-700">{formik.errors.confirmPassword}</div>
                        ) : null}
                    </div>

                    <div className="sm:col-span-3">
                        <label htmlFor="numberPassport" className="block text-sm font-medium leading-6 text-gray-900">
                            N° de passport :
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="numberPassport"
                                id="numberPassport"
                                autoComplete="family-name"
                                onChange={formik.handleChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                        {formik.touched.numberPassport && formik.errors.numberPassport ? (
                            <div className="text-red-700">{formik.errors.numberPassport}</div>
                        ) : null}
                    </div>
                    <div className="sm:col-span-3">
                        <ScInput
                            name="expirationPassport"
                            onChange={formik.handleChange}
                            label="Date éxpiration passport :"
                            type="date"
                            position="right"
                        />
                        {formik.touched.expirationPassport && formik.errors.expirationPassport ? (
                            <div className="text-red-700">{formik.errors.expirationPassport}</div>
                        ) : null}
                    </div>
                    <div className="col-span-full">
                        <label htmlFor="addressResidence" className="block text-sm font-medium leading-6 text-gray-900">
                            Adresse de résidence:
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="addressResidence"
                                id="addressResidence"
                                autoComplete="street-address"
                                onChange={formik.handleChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                        {formik.touched.addressResidence && formik.errors.addressResidence ? (
                            <div className="text-red-700">{formik.errors.addressResidence}</div>
                        ) : null}
                    </div>
                    <div className="sm:col-span-3">
                        <label className="checkbox-input">
                            <input
                                id="condition"
                                type="checkbox"
                                name="condition"
                                onChange={formik.handleChange}
                            />
                            &nbsp;J'ai lu et accepte les conditions de confidentialité
                        </label>
                        {formik.touched.condition && formik.errors.condition ? (
                            <div className="text-red-700">{formik.errors.condition}</div>
                        ) : null}
                    </div>
                </div>
                {modalOpen &&
                    <Modal
                        twoBtn={false}
                        closeModal={handleButtonClick}
                        message={message}
                    />
                }
                <div className="mt-10 flex flex-col items-center ">
                    {loading ?
                        <Loader /> :
                        <>
                            <button
                                type="button"
                                onClick={formik.submitForm}
                                className="bg-blue-secondary text-yellow-primary font-medium rounded-full text-sm px-5 py-2.5 text-center font-bold w-1/6">
                                    {_.upperCase("Valider")}
                            </button>
                        </>
                    }
                </div>
            </div>
        </form>
    );
};
