import * as Yup from "yup";
import { parse } from "date-fns";

export const optionsTotalPersonne = [
  { name: "1", value: 1, selected: true },
  { name: "2", value: 2, selected: false },
  { name: "3", value: 3, selected: false },
  { name: "4", value: 4, selected: false },
  { name: "5", value: 5, selected: false },
  { name: "6", value: 6, selected: false },
  { name: "7", value: 7, selected: false },
  { name: "8", value: 8, selected: false },
];
export const arrayPriceBasique = [
  {date: 3, Basique: 15, basiqueOld: 20 },
  {date: 7, Basique: 18, basiqueOld: 23 },
  {date: 14, Basique: 25, basiqueOld: 33 },
  {date: 21, Basique: 30, basiqueOld: 39 },
  {date: 31, Basique: 36, basiqueOld: 47 },
  {date: 61, Basique: 43, basiqueOld: 55 },
  {date: 92, Basique: 55, basiqueOld: 72 },
  {date: 122, Basique: 80, basiqueOld: 104 },
  {date: 153, Basique: 110, basiqueOld: 143 },
  {date: 183, Basique: 160, basiqueOld: 208 },
  {date: 214, Basique: 204, basiqueOld: 265 },
  {date: 244, Basique: 231, basiqueOld: 300 },
  {date: 275, Basique: 259, basiqueOld: 336 },
  {date: 305, Basique: 286, basiqueOld: 372 },
  {date: 336, Basique: 314, basiqueOld: 408 },
  {date: 365, Basique: 341, basiqueOld: 443 },
/*{date: 365, Basique: 253, basiqueOld: 297 },*/
]
export const optionsTrip = [
  { name: "Responsabilité civile ", value: 2 },
  { name: "Refus visa ", value: 3},
  { name: "Rachat franchise ", value: 4 },
  { name: "Responsabilité civile ,Refus visa ,Rachat franchise ", value: 5 },
  { name: "Multi trip", value: 6 },
];


export const optionsPersonneAges = [
  { name: "0", value: 0, selected: true },
  { name: "1", value: 1, selected: false },
  { name: "2", value: 2, selected: false },
  { name: "3", value: 3, selected: false },
  { name: "4", value: 4, selected: false },
  { name: "5", value: 5, selected: false },
  { name: "6", value: 6, selected: false },
  { name: "7", value: 7, selected: false },
  { name: "8", value: 8, selected: false },
];

export const optionsListCountry = [
  { name: "Afghanistan", value: "Afghanistan", selected: false},
  { name: "Afrique du Sud", value: "Afrique du Sud", selected: false}, 
  { name: "Albanie", value: "Albanie", selected: false},
  { name: "Algérie", value: "Algérie", selected: false},
  { name: "Allemagne", value: "Allemagne", selected: false},
  { name: "Andorre", value: "Andorre", selected: false},
  { name: "Angola", value: "Angola", selected: false},
  { name: "Antigua et Barbuda", value: "Antigua et Barbuda", selected: false},
  { name: "Arabie Saoudite", value: "Arabie Saoudite", selected: false},
  { name: "Argentine", value: "Argentine", selected: false},
  { name: "Arménie", value: "Arménie", selected: false},
  { name: "Aruba", value: "Aruba", selected: false},
  { name: "Australie", value: "Australie", selected: false},
  { name: "Autriche", value: "Autriche", selected: false},
  { name: "Azerbaijan", value: "Azerbaijan", selected: false},
  { name: "Bahamas", value: "Bahamas", selected: false},
  { name: "Bahrein", value: "Bahrein", selected: false},
  { name: "Baléares (îles)", value: "Baléares (îles)", selected: false},
  { name: "Bande de Gaza", value: "Bande de Gaza", selected: false},
  { name: "Bangladesh", value: "Bangladesh", selected: false},
  { name: "Barbade", value: "Barbade", selected: false},
  { name: "Belgique", value: "Belgique", selected: false},
  { name: "Belize", value: "Belize", selected: false},
  { name: "Benin", value: "Benin", selected: false},
  { name: "Bermudes", value: "Bermudes", selected: false},
  { name: "Bhoutan", value: "Bhoutan", selected: false},
  { name: "Bielorussie", value: "Bielorussie", selected: false},
  { name: "Bolivie", value: "Bolivie", selected: false},
  { name: "Bosnie herzégovine", value: "Bosnie herzégovine", selected: false},
  { name: "Botswana", value: "Botswana", selected: false},
  { name: "Brésil", value: "Brésil", selected: false},
  { name: "Brunei", value: "Brunei", selected: false},
  { name: "Bulgarie", value: "Bulgarie", selected: false},
  { name: "Burkina Faso", value: "Burkina Faso", selected: false},
  { name: "Burundi", value: "Burundi", selected: false},
  { name: "Cambodge", value: "Cambodge", selected: false},
  { name: "Cameroun", value: "Cameroun", selected: false},
  { name: "Canada", value: "Canada", selected: false},
  { name: "Canaries (îles)", value: "Canaries (îles)", selected: false},
  { name: "Cap Vert", value: "Cap Vert", selected: false},
  { name: "Chili", value: "Chili", selected: false},
  { name: "Chine", value: "Chine", selected: false},
  { name: "Chypre", value: "Chypre", selected: false},
  { name: "Colombie", value: "Colombie", selected: false},
  { name: "Comores", value: "Comores", selected: false},
  { name: "Congo", value: "Congo", selected: false},
  { name: "Congo (République Démocratique)", value: "Congo (République Démocratique)", selected: false},
  { name: "Corée Du Nord", value: "Corée Du Nord", selected: false},
  { name: "Corée Du Sud", value: "Corée Du Sud", selected: false},
  { name: "Corse", value: "Corse", selected: false},
  { name: "Costa Rica", value: "Costa Rica", selected: false},
  { name: "Côte D'ivoire", value: "Côte D'ivoire", selected: false},
  { name: "Crête", value: "Crête", selected: false},
  { name: "Croatie", value: "Croatie", selected: false},
  { name: "Cuba", value: "Cuba", selected: false},
  { name: "Curaçao", value: "Curaçao", selected: false},
  { name: "Danemark", value: "Danemark", selected: false},
  { name: "Djibouti", value: "Djibouti", selected: false},
  { name: "Dominique", value: "Dominique", selected: false},
  { name: "Egypte", value: "Egypte", selected: false},
  { name: "El Salvador", value: "El Salvador", selected: false},
  { name: "Emirats Arabes", value: "Emirats Arabes", selected: false},
  { name: "Equateur", value: "Equateur", selected: false},
  { name: "Erythrée", value: "Erythrée", selected: false},
  { name: "Espace Schengen", value: "Espace Schengen", selected: false},
  { name: "Espagne", value: "Espagne", selected: false},
  { name: "Estonie", value: "Estonie", selected: false},
  { name: "Etats Unis", value: "Etats Unis", selected: false},
  { name: "Ethiopie", value: "Ethiopie", selected: false},
  { name: "Fidji", value: "Fidji", selected: false},
  { name: "Finlande", value: "Finlande", selected: false},
  { name: "France", value: "France", selected: false},
  { name: "Gabon", value: "Gabon", selected: false},
  { name: "Gambie", value: "Gambie", selected: false},
  { name: "Georgie", value: "Georgie", selected: false},
  { name: "Ghana", value: "Ghana", selected: false},
  { name: "Gibraltar", value: "Gibraltar", selected: false},
  { name: "Grèce", value: "Grèce", selected: false},
  { name: "Grenade", value: "Grenade", selected: false},
  { name: "Groenland", value: "Groenland", selected: false},
  { name: "Guadeloupe", value: "Guadeloupe", selected: false},
  { name: "Guam", value: "Guam", selected: false},
  { name: "Guatémala", value: "Guatémala", selected: false},
  { name: "Guenesey", value: "Guenesey", selected: false},
  { name: "Guinée", value: "Guinée", selected: false},
  { name: "Guinée Bissau", value: "Guinée Bissau", selected: false},
  { name: "Guinée Equatoriale", value: "Guinée Equatoriale", selected: false},
  { name: "Guyane", value: "Guyane", selected: false},
  { name: "Guyane Française", value: "Guyane Française", selected: false},
  { name: "Haiti", value: "Haiti", selected: false},
  { name: "Honduras", value: "Honduras", selected: false},
  { name: "Hongrie", value: "Hongrie", selected: false},
  { name: "Ibiza (îles)", value: "Ibiza (îles)", selected: false},
  { name: "Iles Aland", value: "Iles Aland", selected: false},
  { name: "Iles Bouvet", value: "Iles Bouvet", selected: false},
  { name: "Iles Caïmans", value: "Iles Caïmans", selected: false},
  { name: "Iles Christmas", value: "Iles Christmas", selected: false},
  { name: "Iles Cocos", value: "Iles Cocos", selected: false},
  { name: "Iles Cook", value: "Iles Cook", selected: false},
  { name: "Iles de Man", value: "Iles de Man", selected: false},
  { name: "Iles Féroé", value: "Iles Féroé", selected: false},
  { name: "Iles heard et MacDonald", value: "Iles heard et MacDonald", selected: false},
  { name: "Iles mineures Etats-Unis", value: "Iles mineures Etats-Unis", selected: false},
  { name: "Iles Norfolk", value: "Iles Norfolk", selected: false},
  { name: "Iles Pitcairn", value: "Iles Pitcairn", selected: false},
  { name: "Iles Turques-et-Caïques", value: "Iles Turques-et-Caïques", selected: false},
  { name: "Iles vierges britaniques", value: "Iles vierges britaniques", selected: false},
  { name: "Iles vierges Etats-Unis", value: "Iles vierges Etats-Unis", selected: false},
  { name: "Inde", value: "Inde", selected: false},
  { name: "Indonesie", value: "Indonesie", selected: false},
  { name: "Iran", value: "Iran", selected: false},
  { name: "Iraq", value: "Iraq", selected: false},
  { name: "Irlande", value: "Irlande", selected: false},
  { name: "Islande", value: "Islande", selected: false},
  { name: "Israël", value: "Israël", selected: false},
  { name: "Italie", value: "Italie", selected: false},
  { name: "Jamaique", value: "Jamaique", selected: false},
  { name: "Japon", value: "Japon", selected: false},
  { name: "Jersey", value: "Jersey", selected: false},
  { name: "Jordanie", value: "Jordanie", selected: false},
  { name: "Kazakhstan", value: "Kazakhstan", selected: false},
  { name: "Kenya", value: "Kenya", selected: false},
  { name: "Kirghizistan", value: "Kirghizistan", selected: false},
  { name: "Kiribati", value: "Kiribati", selected: false},
  { name: "Kosovo", value: "Kosovo", selected: false},
  { name: "Koweit", value: "Koweit", selected: false},
  { name: "Laos", value: "Laos", selected: false},
  { name: "Lésotho", value: "Lésotho", selected: false},
  { name: "Lettonie", value: "Lettonie", selected: false},
  { name: "Liban", value: "Liban", selected: false},
  { name: "Libéria", value: "Libéria", selected: false},
  { name: "Libye", value: "Libye", selected: false},
  { name: "Liechtenstein", value: "Liechtenstein", selected: false},
  { name: "Lituanie", value: "Lituanie", selected: false},
  { name: "Luxembourg", value: "Luxembourg", selected: false},
  { name: "Macao", value: "Macao", selected: false},
  { name: "Macédonie", value: "Macédonie", selected: false},
  { name: "Madagascar", value: "Madagascar", selected: false},
  { name: "Malaisie", value: "Malaisie", selected: false},
  { name: "Malawi", value: "Malawi", selected: false},
  { name: "Maldives", value: "Maldives", selected: false},
  { name: "Mali", value: "Mali", selected: false},
  { name: "Malouines", value: "Malouines", selected: false},
  { name: "Malte", value: "Malte", selected: false},
  { name: "Marianne du Nord", value: "Marianne du Nord", selected: false},
  { name: "Maroc", value: "Maroc", selected: false},
  { name: "Marshall (Iles)", value: "Marshall (Iles)", selected: false},
  { name: "Martinique", value: "Martinique", selected: false},
  { name: "Maurice (Iles)", value: "Maurice (Iles)", selected: false},
  { name: "Mauritanie", value: "Mauritanie", selected: false},
  { name: "Mayotte", value: "Mayotte", selected: false},
  { name: "Mexique", value: "Mexique", selected: false},
  { name: "Micronésie", value: "Micronésie", selected: false},
  { name: "Moldavie", value: "Moldavie", selected: false},
  { name: "Monaco", value: "Monaco", selected: false},
  { name: "Mongolie", value: "Mongolie", selected: false},
  { name: "Monténegro", value: "Monténegro", selected: false},
  { name: "Montserrat", value: "Montserrat", selected: false},
  { name: "Mozambique", value: "Mozambique", selected: false},
  { name: "Myanmar (Birmanie)", value: "Myanmar (Birmanie)", selected: false},
  { name: "Namibie", value: "Namibie", selected: false},
  { name: "Nauru", value: "Nauru", selected: false},
  { name: "Népal", value: "Népal", selected: false},
  { name: "Nicaragua", value: "Nicaragua", selected: false},
  { name: "Niger", value: "Niger", selected: false},
  { name: "Nigéria", value: "Nigéria", selected: false},
  { name: "Niue", value: "Niue", selected: false},
  { name: "Norvège", value: "Norvège", selected: false},
  { name: "Nouvelle Calédonie", value: "Nouvelle Calédonie", selected: false},
  { name: "Nouvelle Zélande", value: "Nouvelle Zélande", selected: false},
  { name: "Oman", value: "Oman", selected: false},
  { name: "Ouganda", value: "Ouganda", selected: false},
  { name: "Ouzbékistan", value: "Ouzbékistan", selected: false},
  { name: "Pakistan", value: "Pakistan", selected: false},
  { name: "Palaos", value: "Palaos", selected: false},
  { name: "Palestine", value: "Palestine", selected: false},
  { name: "Panama", value: "Panama", selected: false},
  { name: "Papouasie Nouvelle Guinée", value: "Papouasie Nouvelle Guinée", selected: false},
  { name: "Paraguay", value: "Paraguay", selected: false},
  { name: "Pays Bas", value: "Pays Bas", selected: false},
  { name: "Pays-Bas Caribéens", value: "Pays-Bas Caribéens", selected: false},
  { name: "Perou", value: "Perou", selected: false},
  { name: "Philippines", value: "Philippines", selected: false},
  { name: "Pologne", value: "Pologne", selected: false},
  { name: "Polynésie française", value: "Polynésie française", selected: false},
  { name: "Portugal", value: "Portugal", selected: false},
  { name: "Puerto Rico", value: "Puerto Rico", selected: false},
  { name: "Qatar", value: "Qatar", selected: false},
  { name: "République arabe sahraouie démocratique", value: "République arabe sahraouie démocratique", selected: false},
  { name: "République Centre Africaine", value: "République Centre Africaine", selected: false},
  { name: "République Dominicaine", value: "République Dominicaine", selected: false},
  { name: "République Tcheque", value: "République Tcheque", selected: false},
  { name: "Réunion", value: "Réunion", selected: false},
  { name: "Roumanie", value: "Roumanie", selected: false},
  { name: "Royaume Unis", value: "Royaume Unis", selected: false},
  { name: "Russie", value: "Russie", selected: false},
  { name: "Rwanda", value: "Rwanda", selected: false},
  { name: "Salomon (Iles)", value: "Salomon (Iles)", selected: false},
  { name: "Samoa", value: "Samoa", selected: false},
  { name: "Samoa américaines", value: "Samoa américaines", selected: false},
  { name: "Sao Tome et Principe", value: "Sao Tome et Principe", selected: false},
  { name: "Sardaigne", value: "Sardaigne", selected: false},
  { name: "Sénégal", value: "Sénégal", selected: false},
  { name: "Serbie", value: "Serbie", selected: false},
  { name: "Seychelles", value: "Seychelles", selected: false},
  { name: "Sicile", value: "Sicile", selected: false},
  { name: "Sierra Leone", value: "Sierra Leone", selected: false},
  { name: "Singapore", value: "Singapore", selected: false},
  { name: "Slovaquie", value: "Slovaquie", selected: false},
  { name: "Slovenie", value: "Slovenie", selected: false},
  { name: "Somalie", value: "Somalie", selected: false},
  { name: "Soudan", value: "Soudan", selected: false},
  { name: "Soudan Sud (République)", value: "Soudan Sud (République)", selected: false},
  { name: "Sri Lanka", value: "Sri Lanka", selected: false},
  { name: "St Bartelemy", value: "St Bartelemy", selected: false},
  { name: "St Christophe-et-Niévès", value: "St Christophe-et-Niévès", selected: false},
  { name: "St Helene", value: "St Helene", selected: false},
  { name: "St Lucie", value: "St Lucie", selected: false},
  { name: "St Marin", value: "St Marin", selected: false},
  { name: "St Martin (Française)", value: "St Martin (Française)", selected: false},
  { name: "St Martin (Néerlandaise)", value: "St Martin (Néerlandaise)", selected: false},
  { name: "St Pierre et Miquelon", value: "St Pierre et Miquelon", selected: false},
  { name: "St Vincent & Les Grenadines", value: "St Vincent & Les Grenadines", selected: false},
  { name: "Suède", value: "Suède", selected: false},
  { name: "Suisse", value: "Suisse", selected: false},
  { name: "Surinam", value: "Surinam", selected: false},
  { name: "Svalbard et ile Jan Mayen", value: "Svalbard et ile Jan Mayen", selected: false},
  { name: "Swaziland", value: "Swaziland", selected: false},
  { name: "Syrie", value: "Syrie", selected: false},
  { name: "Taiwan", value: "Taiwan", selected: false},
  { name: "Tajikistan", value: "Tajikistan", selected: false},
  { name: "Tanzanie", value: "Tanzanie", selected: false},
  { name: "Tchad", value: "Tchad", selected: false},
  { name: "Terres australes françaises", value: "Terres australes françaises", selected: false},
  { name: "Territoire britannique Océan Indien", value: "Territoire britannique Océan Indien", selected: false},
  { name: "Thaïlande", value: "Thaïlande", selected: false},
  { name: "Tibet", value: "Tibet", selected: false},
  { name: "Timor oriental", value: "Timor oriental", selected: false},
  { name: "Togo", value: "Togo", selected: false},
  { name: "Tokelau", value: "Tokelau", selected: false},
  { name: "Tonga (Iles)", value: "Tonga (Iles)", selected: false},
  { name: "Trinite et Tobago", value: "Trinite et Tobago", selected: false},
  { name: "Tunisie", value: "Tunisie", selected: false},
  { name: "Turkmenistan", value: "Turkmenistan", selected: false},
  { name: "Turquie", value: "Turquie", selected: false},
  { name: "Tuvalu", value: "Tuvalu", selected: false},
  { name: "Ukraine", value: "Ukraine", selected: false},
  { name: "Vanuatu", value: "Vanuatu", selected: false},
  { name: "Vatican", value: "Vatican", selected: false},
  { name: "Vénézuela", value: "Vénézuela", selected: false},
  { name: "Vietnam", value: "Vietnam", selected: false},
  { name: "Wallis-et-Futuna", value: "Wallis-et-Futuna", selected: false},
  { name: "Yémen", value: "Yémen", selected: false},
  { name: "Zambie", value: "Zambie", selected: false},
  { name: "Zimbawe", value: "Zimbawe", selected: false}
];

export const devisValidationSchema = () => {
  return Yup.object({
    startDate: Yup.date()
      .transform(function (value, originalValue) {
        if (this.isType(value)) return value;
        return parse(originalValue, "dd/MM/yyyy", new Date());
      })
      .typeError("Une date valide")
      // .min("1969-11-13", "Date trop proche"),
      .required(),
    endDate: Yup.date()
      .transform(function (value, originalValue) {
        if (this.isType(value)) return value;
        return parse(originalValue, "dd/MM/yyyy", new Date());
      })
      .typeError("Une date valide")
      // .min("1969-11-13", "Date trop proche"),
      .required(),
  });
};
