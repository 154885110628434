import React from 'react';
import { Routes, Route } from "react-router-dom";

import { HomePage } from './HomePage';
import GarantiPage from './GarantiPage';
import InfoPage from './InfoPage';
import { DevisPage } from './DevisPage';

import Error from '../../_utils/Error'

const PublicRouter = () => {
    return (
        <Routes>
            <Route index element={<HomePage />} />
            <Route path="accueil" element={<HomePage />} />
            <Route path="garanti" element={<GarantiPage />} />
            <Route path="information" element={<InfoPage />} />
            <Route path="devis" element={<DevisPage />} />
            <Route path="*" element={<Error />} />
        </Routes>
    );
};

export default PublicRouter;