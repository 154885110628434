import { ScInput } from "../../../components/ScInput";
import { Select } from "../../../components/Select";

const SupportContent = () => {
    
    const optionReclamation = [
        { name: "Réclamation produit. (contrat/sinistre)", value: "reclamation", selected: true},
        { name: "Rencontrez vous un problème technique sur la plateforme.", value: "technique", selected: false},
    ];
    
    return (
        <div className='px-[25px] pt-[25px] bg-[#F8F9FC] pb-[40px]'>
            <div className='flex items-center justify-between'>
                <h1 className='text-[28px] leading-[34px] font-normal text-[#5a5c69] cursor-pointer'>Dashboard</h1>
            </div>
            <div className='flex mt-[22px] w-full gap-[30px]'>
                <div className='basis-[100%] border bg-white shadow-md cursor-pointer rounded-[4px]'>
                    <div className='bg-[#F8F9FC] flex items-center justify-between py-[15px] px-[20px] border-b-[1px] border-[#EDEDED] mb-[20px]'>
                        <h2 className='text-[#4e73df] text-[16px] leading-[19px] font-bold'>Contacter le support</h2>
                    </div>
                    <div className="w-full">
                        <div className="max-w-2xl mx-auto">
                            <Select label="Type de reclamation:"
                                options={optionReclamation}
                                selectedClassName="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                name="typeReclamation"
                            />
                            <label htmlFor="message" className="block mb-2 text-sm font-medium">Objet de votre demande: </label>
                            <input
                                type="text"
                                name="message"
                                id="message"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            <label htmlFor="message" className="block mb-2 text-sm font-medium">Votre message: </label>
                            <textarea id="message" rows="12" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Ecriver votre demande..."></textarea>
                            <button type="button" className="my-5 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal shadow-[0_4px_9px_-4px_#3b71ca] focus:outline-none focus:ring-0 active:bg-primary-700 ">
                                Envoyer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SupportContent;