import React from "react";
export const RedirectBanner = () => {

    return (
            <div className="text-left m-12 flex flex-col items-center">
                <div className="mb-5">
                    <b className="text-lg text-blue-primary">Important</b> : En cas de visa refusé, les remboursements du forfait frais d'établissement  de visa et de la prime d'assurance ne seront pas acquis si le motif du refus est que le passport,
                    au moment de la demande, expire dans les 3 mois qui suivent la date de retour.
                    Par ailleurs, un justificatif écrit de refus établi par le consulat ou l'ambassade devra etre fourni lors de la demande de remboursement.
                </div>
                <div className="mb-10">
                    <b className="text-base text-blue-primary mb-5">Des prestataires de qualité pour vous accompagner</b><br />
                    <p>OPRA, opérateur agréé, specialiste de la gestion des assurances, implanté en zone Schengen depuis de nombreuses années.
                    GROUPAMA RHONES ALPES AUVERGNE, 827 millions d'euros de chiffre d'affaire. Plus de 2000 collaborateurs.
                    Groupama Assistance, acteur majeur du réseau mondial de l'assistance, gérant plus de 40 millions de clients et intervenant 24H/24,7J/7 dans plus de 150 pays.</p>
                </div>
                <button type="primary" className="focus:outline-none font-medium text-sm px-5 py-2.5 w-1/6 text-2xl uppercase text-blue-secondary bg-yellow-primary rounded-full h-[50px] text-center">
                    Votre devis en ligne
                </button>
            </div>
    );
};
