import React from "react";

export const SecondBanner = () => {
  return (
    <div className="w-full h-[285px] bg-yellow-secondary flex justify-around items-center">
      <div className="w-[1000px] text-center">
        <p className="text-3xl font-bold mb-6">
          L'assurance voyage sur mesure parmi les moins cheres du marché pour
          votre demande de visa a destination de l'espace Schengen
        </p>
        <p className="text-sm">
          Assurance Voyage Schengen est votre contrat d'assurance voyage pour venir dans les pays de l'espace Schengen.
        </p>
        <p className="text-sm">
          Notre indépendence et nos tarifs, néggociés aupres de la compagnie Groupama Rhone-Alpes Auvergne, vous garantissent le fait de bénéficier d'une assurance parmis les moins cheres du marché.
        </p>
      </div>
    </div>
  );
};
