import PropTypes from "prop-types";
import classNames from "classnames";

export const BtnOption = ({ children, onClick, active }) => {
    const btnClass = classNames("text-center border-solid bg-grey-primary rounded-lg w-[150px] height-[50px] cursor-pointer border-4 ",
        {"border-blue-primary": active === true});

    return (
        <div className={btnClass}
             onClick={onClick}
             name={children}>
            <div className="py-4">
                <p className="text-sm font-bold">{children}</p>
            </div>
        </div>
    );
};

BtnOption.propTypes = {
    children: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    active: PropTypes.bool,
};

BtnOption.defaultProps = {
    onClick: () => {},
};
