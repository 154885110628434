import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import TemplatePDFView from "../../../../components/TemplatePDF/TemplatePDFView";
import { Loader } from "../../../../components/Loader";
import contractService from "../../../../_services/contract.service";


const ContractShowCertificatContent = () => {
    const contractId = useParams().id;
    const [loading, setLoading] = useState(true);
    const [contract, setContract] = useState({});
    
    const navigate = useNavigate();

    useEffect(() => {
        contractService.getContract(contractId).then(r =>{
            console.log(r)
            if(r.status === 200){
                console.log(r.data);
                setContract(r.data);
                setLoading(false);
            }else{
                navigate("/dashboard/commande/recapitulatif/" + r.data.id);
            }
        }).catch(err => {
            console.log(err)
        });
    }, [contractId, navigate]);

    return (
        <div>
            <div className='px-[25px] pt-[25px] bg-[#F8F9FC] pb-[40px]'>
                <div className='flex items-center justify-between'>
                    <h1 className='text-[28px] leading-[34px] font-normal text-[#5a5c69] cursor-pointer'>Dashboard</h1>
                </div>
            </div>
            { loading ? <div className="p-12 w-full flex justify-around items-center"><Loader /></div>  : 
                <>
                    <div className='bg-[#F8F9FC] flex items-center justify-between py-[15px] px-[20px] border-b-[1px] border-[#EDEDED] mb-[20px]'>
                        <h2 className='text-[#4e73df] text-[16px] leading-[19px] font-bold'>Visualisation du certificat</h2>
                    </div>
                    <p>Certificat de {contract.lastname} {contract.firstname}</p>
                    <TemplatePDFView contract={contract} />
                </>
            }
        </div>
    );
}

export default ContractShowCertificatContent;