import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export const ScInput = ({
  id,
  disabled,
  icon,
  label,
  placeholder,
  position,
  required,
  type,
  value,
  onChange,
  name,
}) => {

  const inputClass = classNames(
    "h-[40px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5",
    {
      "pl-10": position === "left",
      "pr-10": position === "right",
    }
  );
  const iconClass = classNames(
    "absolute inset-y-0 flex items-center pointer-events-none",
    {
      "left-3": position === "left",
      "right-3": position === "right",
    }
  );

  return (
    <div className="w-[175px]">
      <label
        htmlFor={id}
        className={`block mb-1 text-sm font-medium text-gray-900`}
      >
        {label}
      </label>
      <div className="relative">
        {icon && <div className={iconClass}>{icon}</div>}
        <input
          id={id}
          type={type}
          className={inputClass}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          onChange={onChange}
          defaultValue={value}
          name={name}
        />
      </div>
    </div>
  );
};

ScInput.propTypes = {
  icon: PropTypes.object,
  position: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.number,
  value: PropTypes.any,
};

ScInput.defaultProps = {
  icon: null,
  position: "",
  width: 150,
  value: '',
};
