import React, {useEffect, useState} from 'react';
import { Routes, Route } from "react-router-dom"
import { DashboardLayout } from './index';
import ContractCreateContent from './Content/Contract/ContractCreateContent';
import ContractListContent from './Content/Contract/ContractListContent';
import ContractDetailContent from './Content/Contract/ContractDetailContent';
import ContractRecapContent from './Content/Contract/ContractRecapContent';
import ContractPaiementContent from './Content/Contract/ContractPaiementContent';
import SinistreDeclared from './Content/Sinister/SinistreDeclared';
import CommandListValide from './Content/Sinister/CommandListValide';
import SinistreCreated from './Content/Sinister/SinistreCreated';
import ProfileContent from './Content/ProfileContent';
import SupportContent from './Content/SupportContent';
import Error from '../../_utils/Error'
import jwt from "jwt-decode";
import accountService from '../../_services/account.service';
import userService from "../../_services/user.service";
import ContractShowCertificatContent from './Content/Contract/ContractShowCertificatContent';
import ContractModifyContent from './Content/Contract/ContractModifyContent';

const DashboardRouter = () => {
    const [ user, setUser ] = useState({});
    const claim = jwt(accountService.getToken());
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        if(accountService.isLogged()){
            userService.getCredentialByEmail({email: claim.sub}).then(response => {
                setUser(response.data);
                console.log(response.data)
                setLoading(false);
            }).catch(err => {
                console.log(err);
            })
        }
    }, [claim.sub])

    return (
        <Routes>
            <Route element={<DashboardLayout name={user.firstname}/>}>
                <Route index element={<ProfileContent loading={loading} user={user} email={claim.sub} />} />
                <Route path="commande" element={<ContractListContent id={user.id} />} />
                <Route path="commande/create" element={<ContractCreateContent id={user.id}/>} />
                <Route path="commande/recapitulatif/:id" element={<ContractRecapContent />} />
                <Route path="commande/paiement" element={<ContractPaiementContent />} />
                <Route path="commande/affichage/:id" element={<ContractDetailContent />} />
                <Route path="commande/modification/:id" element={<ContractModifyContent />} />
                <Route path="commande/affichage/certificat/:id" element={<ContractShowCertificatContent />} />
                <Route path="sinistre" element={<SinistreDeclared id={user.id} />} />
                <Route path="sinistre/command" element={<CommandListValide id={user.id} />} />
                <Route path="sinistre/command/:id" element={<SinistreCreated />} />
                <Route path="support" element={<SupportContent/>}/>
                <Route path="*" element={<Error/>}/>
            </Route>
        </Routes>
    );
};

export default DashboardRouter;