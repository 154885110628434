import React, { useEffect, useState } from "react";
import styles from "../../../components/Banner/style.module.scss";
import {DocumentList} from "../../Public/DevisPage/DocumentList";
import accountService from "../../../_services/account.service";
import { useSearchParams, Link } from "react-router-dom";
import { Footer } from "../../../components/Footer";
import { Navbar } from "../../../components/Navbar";
import { Loader } from "../../../components/Loader";


const ConfirmRegistrationPage = () => {
    const [queryParameters] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("En attente de la reponse du serveur");
    const tokenParams = queryParameters.get("token");

    useEffect(() => {
        accountService.confirmation(tokenParams).then(r => {
            if(r.status === 200){
                setMessage(r.data);
                setLoading(false);
            }else{
                setMessage("Une erreur s'est produite contacter l'administrateur");
                setLoading(false);
            }
        }).catch(err => {
            console.error(err)
        });
    }, [tokenParams])

    return (
            <>
                <Navbar />
                    <div className={`${styles.bgBlock} w-full h-[470px] flex justify-around items-center`}>
                    { loading ?  <div className="p-12 w-full flex justify-around items-center"><Loader /></div> :
                        <div className="w-[1000px] text-center">
                            <p className="text-2xl font-bold mb-4">
                                Confirmation de votre Compte
                            </p>
                            <p className="text-sm">
                                {message}
                            </p>
                            <Link to="/auth/">
                                <button type="button" className="font-bold mt-4 w-1/6 bg-blue-secondary text-yellow-primary focus:outline-none font-medium rounded-full font-bold  text-sm px-5 py-2.5 text-center">
                                    Page de connexion
                                </button>
                            </Link>
                        </div>
                    }
                    </div>
                <DocumentList />
                <Footer />
            </>
    );
};

export default ConfirmRegistrationPage;