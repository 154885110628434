// Logo
import SchengenLogo from './schengen-logo.png';

// Icons
import { Calendar } from "./icons/calendar.js";
import { Arrow } from "./icons/arrow.js";
import Attestation from "./icons/icon-attestation.png";
import Contrat from "./icons/icon-contrat.png";
import Map from "./icons/icon-map.png";
import Planet from "./icons/icon-planet.png";

// Images
import EuropeanFlag from "./images/european-flag-bg.jpeg"
import SchengenMap from "./images/carte-schengen.png";

export const Icons = {
  Calendar,
  Arrow,
  Attestation,
  Contrat,
  Map,
  Planet,
};

export const Images = {
  SchengenLogo,
  EuropeanFlag,
  SchengenMap
};
