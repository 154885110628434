import _ from "lodash";
import React from "react";
import styles from "./style.module.scss";

export const SevenBanner = () => {
  return (
    <div className="bg-yellow-test">
      <div className={`${styles.flagBg} w-full h-full py-14 pl-[50px]`}>
        <div className="text-center">
          <h3 className="text-3xl font-bold text-blue-secondary">
            {_.upperCase("Ambassades, consulats, administrations")}
          </h3>
          <p className="text-2xl text-blue-primary mb-2">
            Controlez en temps réel la validité d'un contrat d'Assurance Voyage
            Schengen
          </p>
          <div className="w-full flex justify-center mt-12">
            <div className="w-[200px] bg-blue-secondary rounded-full h-[50px] px-5 py-2.5 text-center pt-[12px]">
              <span className="text-yellow-primary uppercase h-full text-center ">
                {_.upperCase("Controle en ligne")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
