import {useMemo, useState} from "react";
import { ScInput } from "../../../../components/ScInput";
import { Select } from "../../../../components/Select";
import { optionsTotalPersonne, optionsPersonneAges } from "./_const";
import { ScButton } from "../../../../components/ScButton";
import {BtnOption} from "../../../../components/BtnOption";
import { Selection } from "./Selection";
import _ from "lodash";

function useToggle(initialValue = false) {
  const [active, setActive] = useState(initialValue);
  const handle = function (){
    setActive(v => !v);
  }
  return [
      active,
      handle
  ]
}

export const DevisVoyage = ({ formik, prices }) => {
  const { handleChange, values, submitForm } = formik;
  const [option, handleOption] = useOption(values.option);
  const [active, handle] = useToggle(values.option.includes("Responsabilité civile "));
  const [active1, handle1] = useToggle(values.option.includes("Refus visa "));
  const [active2, handle2] = useToggle(values.option.includes("Rachat franchise "));
  const [active3, handle3] = useToggle(values.option.includes("Multi trip"));
  const [showDayDiff, setDayDiff] = useState(1);

  function useOption(options = []) {
    const [option, setOption] = useState(options);
    const handleOption = function (active, opt) {
      if (active && option.includes(opt)) {
        const rest = option.filter(el => el !== opt)
        setOption(rest);
        values.option = rest;
      } else {
        setOption(option => [...option, opt]);
        values.option = [...option, opt];
      }
    }
    return [
      option,
      handleOption
    ]
  }

  const resultPrice = useMemo(() => {
    prices.sort(function (a, b) { return a.duration - b.duration; });
    const start = new Date(values.startDate);
    const end = new Date(values.endDate);
    const diff = end.getTime() - start.getTime();
    let daydiff = (diff / (1000 * 60 * 60 * 24) > 365) ? 0 : diff / (1000 * 60 * 60 * 24);
    setDayDiff(daydiff);
    let findPrice = prices.find((price) => price.duration >= daydiff);
    let resultPrice = 0;

    if(daydiff > 0){
      for (let index = 0; index < parseInt(values.totalPersonne); index++) {
        resultPrice = resultPrice + findPrice.basic
        if (values.option.includes("Responsabilité civile ")) {
          resultPrice = resultPrice + findPrice.civilLiability
        }
        if (values.option.includes("Refus visa ")) {
          resultPrice = resultPrice + findPrice.visaRepayment
        }
        if (values.option.includes("Rachat franchise ")) {
          resultPrice = resultPrice + findPrice.buyback
  
        }
      }
      for (let index = 0; index < parseInt(values.totalPersonneAges); index++) {
        resultPrice = resultPrice + findPrice.basicOld
        if (values.option.includes("Responsabilité civile ")) {
          resultPrice = resultPrice + findPrice.civilLiability
        }
        if (values.option.includes("Refus visa ")) {
          resultPrice = resultPrice + findPrice.visaRepayment
        }
        if (values.option.includes("Rachat franchise ")) {
          resultPrice = resultPrice + findPrice.buybackOld
  
        }
      }
    }

    return resultPrice;
  }, [values.startDate, values.endDate, values.totalPersonne, values.totalPersonneAges, values.option, prices]);

  return (
    <>
      <div className="border w-[700px] shadow rounded-lg p-5">
        <div className="flex">
          <div className="flex mr-5">
            <ScInput
              name="startDate"
              value={values.startDate}
              onChange={handleChange}
              label="Date de départ :"
              type="date" />
          </div>
          <div className="flex items-center">
            <ScInput
              name="endDate"
              value={values.endDate}
              onChange={handleChange}
              label="Date de retour :"
              type="date" />
          </div>
        </div>
        <div className="mt-5">
          <Select
            label="Nombre total de personne(s) à assurer :"
            options={optionsTotalPersonne}
            selectedClassName="w-[75px]"
            name="totalPersonne"
            value={values.totalPersonne}
            onChange={handleChange} />
        </div>
        <div className="mt-5">
          <Select
            label="Dont nombre total de personne(s) de plus de 79 ans :"
            options={optionsPersonneAges}
            selectedClassName="w-[75px]"
            name="totalPersonneAges"
            value={values.totalPersonneAges}
            onChange={handleChange} />
        </div>
        <div className="mt-5">
          <p className="text-lg font-bold">Choisissez vos options :</p>
          <div className="flex justify-between w-full mb-5 mt-2">
            <BtnOption
              active={active}
              onClick={() => {
                handle();
                handleOption(active, "Responsabilité civile ");
              }}
              children={"Responsabilité civile"} />

            <BtnOption
              active={active1}
              onClick={() => {
                handle1();
                handleOption(active1, "Refus visa ");
              }}
              children={"Refus visa"} />

            <BtnOption
              active={active2}
              onClick={() => {
                handle2();
                handleOption(active2, "Rachat franchise ");
              }}
              children={"Rachat franchise"} />

            {showDayDiff >= 365 && <BtnOption
              active={active3}
              onClick={() => {
                handle3();
                handleOption(active3, "Multi trip ");
              }}
              children={"Multi trip"} />}
          </div>
          <div className="flex justify-end">
            <ScButton
              type="primary"
              children={_.upperCase("Souscrire")}
              className="font-bold w-[150px]"
              onClick={submitForm} />
          </div>
        </div>
      </div>
      <Selection
        dayDiff={showDayDiff}
        option={option}
        price={resultPrice}
        totalPersonne={values.totalPersonne}
        nbrOldPeople={values.totalPersonneAges} />
    </>
  );
};
