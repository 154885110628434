import { useState } from "react";
import { Select } from "../../components/Select";
import { ScInput } from "../../components/ScInput";
import { optionsListCountry } from "../../pages/Public/DevisPage/Form/_const";
import {contractValidationSchema} from "../../pages/Dashboard/Content/Contract/_const";

function useHandleContactName(nameDefault) {
    const [name, setName] = useState(nameDefault);
    const handleName = function (nameAssure){
        setName(nameAssure)
    }
    return [
        name,
        handleName
    ];
}

export const FormContract = (props) => {
    const [contratLastName, setFullNameContract] = useHandleContactName('Contrat ' + (props.mykey + 1));
    const [contratFirstName, setFullNameContract1] = useHandleContactName('');
    const [soloFormContract, setSoloFormContract] = useState({
        firstname: null,
        lastname: null,
        nationality: null,
        countryReside: null,
        born: null,
        numberPass: null,
        expirPass: null,
        objectifTrip: null,
        email: null,
        profession: null,
        addressReside: null,
        dateStart: null,
        dateEnd: null,
    });
    const [errors, setErrors] = useState({});

    const toggleCheckForm = async (e) => {
        e.preventDefault();
        try {
            await contractValidationSchema().validate(soloFormContract, { abortEarly: false });
            setErrors({})
            props.listenErrorFormContract(props.mykey, false);
            props.toggleAccordion()
        } catch (error) {
            const validationErrors = {};
            error.inner.forEach((err) => {
              if (err.path !== undefined) {
                validationErrors[err.path] = err.errors[0];
              }
            });
            props.listenErrorFormContract(props.mykey, true);
            setErrors(validationErrors);
        }
    };

    const handleFormContract = (key, data) => {
        setSoloFormContract(prev => ({
            ...prev,
            [key]: data
        }));
    }


    return (
        <div className="border rounded-md mb-1">
            <button className="w-full p-4 text-left bg-gray-200  hover:bg-gray-300 transition duration-300" onClick={props.toggleAccordion}>
                <h2 className='text-[20px] font-bold'>{contratLastName + ' ' + contratFirstName}</h2>
                <span className={`float-right transform ${props.isOpen ? 'rotate-180' : 'rotate-0'} transition-transform duration-300`}>
                    &#9660;
                </span>
            </button>
            <div className={`p-4 bg-white w-full ${props.isOpen ? '' : 'hidden'}`}>
                <form method="post">
                    <div className="px-48 py-24  flex justify-around">
                        <div className="border-b border-gray-900/10 pb-15 border shadow rounded-lg p-5 w-4/12 ">
                            <h2 className='text-[#4e73df] text-[16px] leading-[19px] font-bold'>Informations Personnelles</h2>
                            <div className="sm:col-start-1 col-end-1">
                                <label htmlFor="gender" className="block text-sm font-medium leading-6 text-gray-900">
                                    Genre
                                </label>
                                <select name="gender"
                                    onChange={(e) => { props.handleChangeFormContrat(props.mykey, e.target.name, e.target.value); }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                    <option value="male">Monsieur</option>
                                    <option value="female">Madame</option>
                                </select>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="lastname" className="block text-sm font-medium leading-6 text-gray-900">
                                    Nom
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="lastname"
                                        id="lastname"
                                        autoComplete="given-name"
                                        onChange={(e) => { props.handleChangeFormContrat(props.mykey, e.target.name, e.target.value); setFullNameContract(e.target.value); handleFormContract(e.target.name, e.target.value);  }}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                {errors.lastname ? (
                                    <div className="text-red-700">{errors.lastname}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="firstname" className="block text-sm font-medium leading-6 text-gray-900">
                                    Prénom
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="firstname"
                                        id="firstname"
                                        autoComplete="given-name"
                                        onChange={(e) => { props.handleChangeFormContrat(props.mykey, e.target.name, e.target.value); setFullNameContract1(e.target.value); handleFormContract(e.target.name, e.target.value); }}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                {errors.firstname ? (
                                    <div className="text-red-700">{errors.firstname}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="nationality" className="block text-sm font-medium leading-6 text-gray-900">
                                    Nationalité :
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="nationality"
                                        id="nationality"
                                        autoComplete="given-name"
                                        onChange={(e) => { props.handleChangeFormContrat(props.mykey, e.target.name, e.target.value); handleFormContract(e.target.name, e.target.value); }}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                {errors.nationality ? (
                                    <div className="text-red-700">{errors.nationality}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="countryReside" className="block text-sm font-medium leading-6 text-gray-900">
                                    Pays de residence
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="countryReside"
                                        id="countryReside"
                                        autoComplete="family-name"
                                        onChange={(e) => { props.handleChangeFormContrat(props.mykey, e.target.name, e.target.value); handleFormContract(e.target.name, e.target.value); }}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                {errors.countryReside ? (
                                    <div className="text-red-700">{errors.countryReside}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-3">
                                <ScInput
                                    type="date"
                                    name="born"
                                    id="born"
                                    onChange={(e) => { props.handleChangeFormContrat(props.mykey, e.target.name, e.target.value); handleFormContract(e.target.name, e.target.value); }}
                                    label=" Date de naissance :"
                                    position="right"
                                />
                                {errors.born ? (
                                    <div className="text-red-700">{errors.born}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-3">
                                <Select
                                    label="Langue de communication:"
                                    options={optionsListCountry}
                                    selectedClassName="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    name="speaking"
                                    onChange={(e) => { props.handleChangeFormContrat(props.mykey, e.target.name, e.target.value); }}
                                />
                            </div>
                        </div>
                        <div className="border-b border-gray-900/10 pb-12 border shadow rounded-lg p-5 w-4/12">
                            <h2 className='text-[#4e73df] text-[16px] font-bold'>Informations additionnelles</h2>
                            <div className="sm:col-span-3">
                                <label htmlFor="born" className="block text-sm font-medium leading-6 text-gray-900">
                                    N° passport :
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="numberPass"
                                        id="numberPass"
                                        autoComplete="family-name"
                                        onChange={(e) => { props.handleChangeFormContrat(props.mykey, e.target.name, e.target.value); handleFormContract(e.target.name, e.target.value); }}
                                        className="block  w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                {errors.numberPass ? (
                                    <div className="text-red-700">{errors.numberPass}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-3">
                                <ScInput
                                    type="date"
                                    name="expirPass"
                                    id="expirPass"
                                    onChange={(e) => { props.handleChangeFormContrat(props.mykey, e.target.name, e.target.value); handleFormContract(e.target.name, e.target.value); }}
                                    label="Date d'éxpir. passport :"
                                    position="right"
                                />
                                {errors.expirPass ? (
                                    <div className="text-red-700">{errors.expirPass}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="objectifTrip" className="block text-sm font-medium leading-6 text-gray-900">
                                    Objet de votre visite :
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="objectifTrip"
                                        id="objectifTrip"
                                        autoComplete="given-name"
                                        onChange={(e) => { props.handleChangeFormContrat(props.mykey, e.target.name, e.target.value); handleFormContract(e.target.name, e.target.value); }}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                {errors.objectifTrip ? (
                                    <div className="text-red-700">{errors.objectifTrip}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    Email :
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        autoComplete="family-name"
                                        onChange={(e) => { props.handleChangeFormContrat(props.mykey, e.target.name, e.target.value); handleFormContract(e.target.name, e.target.value); }}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                {errors.email ? (
                                    <div className="text-red-700">{errors.email}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="profession" className="block text-sm font-medium leading-6 text-gray-900">
                                    Profession :
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="profession"
                                        id="profession"
                                        autoComplete="given-name"
                                        onChange={(e) => { props.handleChangeFormContrat(props.mykey, e.target.name, e.target.value); handleFormContract(e.target.name, e.target.value); }}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                {errors.profession ? (
                                    <div className="text-red-700">{errors.profession}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="addressReside" className="block text-sm font-medium leading-6 text-gray-900">
                                    Adresse de résidence (*) :
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="addressReside"
                                        id="addressReside"
                                        autoComplete="family-name"
                                        onChange={(e) => { props.handleChangeFormContrat(props.mykey, e.target.name, e.target.value); handleFormContract(e.target.name, e.target.value); }}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                {errors.addressReside ? (
                                    <div className="text-red-700">{errors.addressReside}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="border-b border-gray-900/10 pb-12 border shadow rounded-lg p-5 w-4/12">
                            <h2 className='text-[#4e73df] text-[16px] leading-[19px] font-bold'>Vos choix</h2>
                            <div className="sm:col-start-1 col-end-4">
                                <Select
                                    label="Langue de certification :"
                                    options={optionsListCountry}
                                    selectedClassName="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    name="speakCertif"
                                    onChange={(e) => { props.handleChangeFormContrat(props.mykey, e.target.name, e.target.value); }}
                                />
                            </div>
                            <div className="sm:col-start-4 col-end-8">
                                <Select
                                    label="Pays de destination :"
                                    options={optionsListCountry}
                                    selectedClassName="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    name="destination"
                                    onChange={(e) => { props.handleChangeFormContrat(props.mykey, e.target.name, e.target.value); }}
                                />
                            </div>
                            <div className="sm:col-span-3">
                                <ScInput
                                    type="date"
                                    name="dateStart"
                                    id="dateStart"
                                    onChange={(e) => { props.handleChangeFormContrat(props.mykey, e.target.name, e.target.value); handleFormContract(e.target.name, e.target.value); }}
                                    label="Date de départ :"
                                    position="right"
                                />
                                {errors.dateStart ? (
                                    <div className="text-red-700">{errors.dateStart}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-3">
                                <ScInput
                                    type="date"
                                    name="dateEnd"
                                    id="dateEnd"
                                    onChange={(e) => { props.handleChangeFormContrat(props.mykey, e.target.name, e.target.value); handleFormContract(e.target.name, e.target.value); }}
                                    label="Date de retour :"
                                    position="right"
                                />
                                {errors.dateEnd ? (
                                    <div className="text-red-700">{errors.dateEnd}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-3">
                                <div>
                                    <label className="checkbox-input">
                                        <input
                                            id="fifthteenDay"
                                            type="checkbox"
                                            name="fifthteenDay"
                                            onChange={(e) => { props.handleChangeFormContrat(props.mykey, e.target.name, e.target.checked) }}
                                        />
                                        &nbsp;+15 jours
                                    </label>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <div>
                                    <label className="checkbox-input">
                                        <input
                                            id="repaymentVisa"
                                            type="checkbox"
                                            name="repaymentVisa"
                                            onChange={(e) => { console.log(e); props.handleChangeFormContrat(props.mykey, e.target.name, e.target.checked) }}
                                        />
                                        &nbsp;Refus de visa
                                    </label>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <div>
                                    <label className="checkbox-input">
                                        <input
                                            id="responsabilityCivil"
                                            type="checkbox"
                                            name="responsabilityCivil"
                                            onChange={(e) => { props.handleChangeFormContrat(props.mykey, e.target.name, e.target.checked) }}
                                        />
                                        &nbsp;Responsabilité civile
                                    </label>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <div>
                                    <label className="checkbox-input">
                                        <input
                                            id="noFranchise"
                                            type="checkbox"
                                            name="noFranchise"
                                            onChange={(e) => { props.handleChangeFormContrat(props.mykey, e.target.name, e.target.checked) }}
                                        />
                                        &nbsp;Sans franchise
                                    </label>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <div>
                                    <label className="checkbox-input">
                                        <input
                                            id="multiTrip"
                                            type="checkbox"
                                            name="multiTrip"
                                            onChange={(e) => { props.handleChangeFormContrat(props.mykey, e.target.name, e.target.checked) }}
                                        />
                                        &nbsp;Multi trip
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {props.isDeleted && (<button type="button" onClick={props.deleteAccordion} className="focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center bg-red-600 text-white">Supprimer</button>)}
                    <button type="submit" onClick={toggleCheckForm} className="focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center bg-blue-secondary text-yellow-primary">Terminer ce contrat</button>
                </form>
            </div>
        </div>
    );
};