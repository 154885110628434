import React from "react";
import { Images } from "../../../../assets";

export const InfoContent = () => {

    return (
        <>
            <div className="p-24">
                <div className="pb-12">
                    <h1 className="text-2xl mb-3 font-bold">Pour commencer, dit on Schengen ou Shengen ?</h1>

                    <p>La bonne reponse c'est Shengen.
                        Il sagit d'une petite ville du Luxembourg qui a donné son nom à l'accord qui vise à supprimer les controles aux frontières dans ce qui est desormais devenu l'espace Schengen.<br />
                        Il est constitué par les pays Européens figurant sur la carte ci-desous et qui sont membres de la dite zone Schengen.
                    </p>
                </div>
                <div className="pb-24">
                    <img className="float-right" src={Images.SchengenMap} alt="Schengen map" />
                    
                    <h2 className="text-blue-primary text-2xl mb-3 font-bold">Controler la date de validité de votre passsport</h2>

                    <p className="pb-12">Sa date d'éxpiration doit etre supérieur de trois mois a celle à celle de la date d'éxpiration de votre demande de visa.</p>

                    <h2 className="text-blue-primary text-2xl mb-3 font-bold">Faire une demande de visa Schengen</h2>

                    <p className="pb-12">Utilisez notre calculateur automatique visa Schengen pour determiner en trois clics s'il vous faut faire une demande de visa ou non.</p>

                    <h2 className="text-blue-primary text-2xl mb-3 font-bold">Pays de la zone Schengen</h2>
                    <p className="pb-12">Liste des pays de la zone Schengen :<br />
                        Allemagne, Autriche, Belgique, Croatie, Danemark, Espagne, Estonie, Finlande, France, Grèce, Hongrie, Islande, Italie, Lettonie,<br />
                        Liechtenstein, Lituanie, Luxembourg, Malte, Norvège, Pays-Bas, Pologne, Portugal, République tchèque, Slovaquie, Slovénie, Suède et Suisse.</p>

                    <h2 className="text-blue-primary text-2xl mb-3 font-bold">Préparer certains documents concernant votre voyage qui peuvent varier selon les pays.</h2>

                    <p className="pb-12">Titre de transport<br />
                        Invitation, attestation d'accueil<br />
                        Attestation d'assurance Schengen</p>

                    <h2 className="text-blue-primary text-2xl mb-3 font-bold">Prévoir les frais de visa de séjour</h2>

                    <p className="pb-12" >Attention, le visa peut vous etre refusé sans explication par le consulat ou l'ambassade.<br />
                        Le contrat assurance voyage Schengen rembourse ces frais dans la limite de 50 euro sur simple justificatif de refus par l'ambassade ou le consulat.<br /><br />
                        Consultez la rubrique "garanties" dont le contenu va bien au dela des obligations Schengen pour l'obtention du visa ainsi que le prix de l'assurance voyage Schengen.</p>
                </div>
            </div>
        </ >
    );
};
