export const actuContent = [
  {
    id: 1,
    title:
      "Arnaques qux rendez-vous pour les vasas Schengen délivrés par l'Italie: mode d'emploi",
    content:
      "Au Maroc, la difficulté de l'obtention d'un rendez-vous pour déposer une demande de visa Schengen auprès de l'Italie enflamme l'opinion publique. Les déposants font face à un calvaire: celui de pouvoir trouver un créneau libre",
  },
  {
    id: 2,
    title:
      "Emirats Arabes Unis : vers des visas touristiques longue durée",
    content:
      "C'est une belle opportunité qui s'offre aux ALgériens envisageant et rêvant d'aller aux Emirats arabes unis pour un séjour touristique.",
  },
  {
    id: 3,
    title:
      "L'ambassade de France à Dakar (encore) au coeur d'une enquête pour traffic de visa",
    content:
      "Après l'affaire de traffic de visas et de faux passeoirt qui avait éclaboussé deux députés et des membres du mouvement ⟪Y'en a marre⟫,...",
  },
];
