import {PDFDownloadLink} from "@react-pdf/renderer";
import TemplatePDF from "./TemplatePDF";

const TemplatePDFLink = (props) =>  {
    const { contract } = props;
    return (
        <>
            <PDFDownloadLink document={<TemplatePDF contract={contract} />} fileName={`certificat_${contract.lastname}_${contract.firstname}.pdf`} className="underline hover:decoration-dashed">
                {({ blob, url, loading, error }) =>
                    loading ? 'Chargement du certificat' : 'Télécharger le certificat'
                }
            </PDFDownloadLink>
        </>
    )
}

export default TemplatePDFLink;