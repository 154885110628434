import React from "react";
import { Link } from "react-router-dom";

export const Middle = () => {
  return (
    <div className="w-[550px]">
      <div>
        <h3 className="mb-2 text-xl font-bold">Guide visa Schengen</h3>
        <div>
          <Link to="/" className="text-blue-primary underline">
            Voyager dans l'espace Schengen
          </Link>
        </div>
        <div>
          <Link to="/" className="text-blue-primary underline">
            Mieux comprendre les garanties des assurances visa Schengen
          </Link>
        </div>
        <div>
          <Link to="/" className="text-blue-primary underline">
            Guide Assurance Schengen
          </Link>
        </div>
        <div>
          <Link to="/" className="text-blue-primary underline">
            F.A.Q
          </Link>
        </div>
        <div>
          <Link to="/" className="text-blue-primary underline">
            Conseils de voyage dans l'espace Schengen et l'U.E.
          </Link>
        </div>
      </div>

      <div>
        <h3 className="mb-2 text-xl font-bold">Assurance Voyage Schengen</h3>
        <div>
          <Link to="/" className="text-blue-primary underline">
            Qui-somme-nous ?
          </Link>
        </div>
        <div>
          <Link to="/" className="text-blue-primary underline">
            Contactez-nous
          </Link>
        </div>
        <div>
          <Link to="/" className="text-blue-primary underline">
            Souscrire assurance Schengen en ligne
          </Link>
        </div>
        <div>
          <Link to="/" className="text-blue-primary underline">
            Déclarer un sinistre
          </Link>
        </div>
        <div>
          <Link to="/" className="text-blue-primary underline">
            Réclamations
          </Link>
        </div>
      </div>
    </div>
  );
};
