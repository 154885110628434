import React from "react";

export const Price = () => {
  return (
    <div className="px-12 w-46">
      <h1 className="font-bold text-4xl text-white mb-3">
        Assurance Espace <br />
        <span className="text-yellow-primary">Schengen</span>
      </h1>
      <p className="font-bold text-white text-lg">à partir de 0.63€/jour*</p>
    </div>
  );
};
