import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import commandService from "../../../../_services/command.service";
import { Loader } from "../../../../components/Loader";
import _ from "lodash";

const ContractPaiementContent = () => {
    const [queryParameters] = useSearchParams();
    const [resultPayment, setResultPayment] = useState({});
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const tokenParams = queryParameters.get("token");

    useEffect(()=>{
        commandService.paymentDetailsCommand(tokenParams).then(r => {
            console.log(r.data);
            setResultPayment(r.data)
            setLoading(false)
        }).catch(err => {
            console.log(err)
        })
    }, [tokenParams]);

    const handleNavigateAfterPayment = () => {
        navigate('/dashboard/commande/affichage/'+ resultPayment.privateDataList.privateData[0].value);
    }


    return (
        <>
        <div className="p-6">
                <p className="text-[28px] leading-[34px] font-normal text-[#5a5c69]">Détails du Paiement</p>
            </div>
        <div  className='px-[25px] pt-[25px] bg-[#F8F9FC] pb-[40px] flex flex-col items-center'>
            {loading ? <div className="p-12 w-full flex justify-around items-center"><Loader /></div> : <div className="mt-[22px] gap-[30px] border w-[700px] shadow rounded-lg p-5">
                    <div className="flex justify-center">
                        <div className="flex mr-5">
                        {resultPayment.result.shortMessage === "ACCEPTED"? <h2>Votre paiement a été effectué avec succes</h2> : <h2>Votre paiement n'a pas abouti contacté votre banque </h2>} 
                        </div>
                    </div>
                    <div className="mt-5">
                        <p>numéro de la transaction : {resultPayment.transaction.id}</p>
                    </div>
                    <div className="mt-5">
                        <p>Prix : {resultPayment.order.amount.slice(0, -2) + "." + resultPayment.order.amount.slice(-2)}</p>
                    </div>
                    <div className="mt-5">
                        <p>identifiant de la command : {resultPayment.order.ref}</p>
                    </div>
                    <div className="mt-5">
                        <p>date: {resultPayment.transaction.date}</p>
                    </div>
                    <div className="mt-5">
                        <p>numero de carte : {resultPayment.card.number}</p>
                    </div>
                    <div className="flex justify-center mt-5">
                        <button type="button" className="focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center bg-blue-secondary text-yellow-primary" onClick={handleNavigateAfterPayment}>
                            {_.upperCase("Acceder a mes contrats")}
                        </button>
                    </div>
            </div> }
        </div>
        </>
    )
}

export default ContractPaiementContent;