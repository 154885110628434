import React from "react";
import {renderStandard, renderMultiTrip} from "./_const";
export const GarantiContent = () => {

    return (
        <div className="w-full h-min[350px] bg-grey-primary flex justify-around items-center">
            <div className="w-full flex justify-around">
                <div className="bg-grey-primary w-6/12">
                    <h3 className="text-center text-2xl mb-3 font-bold">STANDARD</h3>
                    {renderStandard.map((value, key) => (
                        <React.Fragment key={key}>
                                <span className="text-blue-primary h-[40px] mb-2 flex items-center pl-3 text-sm">
                                    {value.message}
                                </span>
                                <hr style={{ color: "grey",}} />
                        </React.Fragment>
                    ))}
                </div>
                <div className="w-6/12 pl-6 bg-blue-primary">
                    <h3 className="text-center text-white text-2xl mb-3 font-bold">MULTI TRIP</h3>
                    {renderMultiTrip.map((value, key) => (
                        <React.Fragment key={key}>
                            <span className="h-[40px] mb-2 flex items-center pl-3 text-white text-sm">{value.message}</span>
                            <hr />
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};
