import React from "react";
import { Footer } from "../../../components/Footer";
import { Navbar } from "../../../components/Navbar";
import {Banner} from "../../../components/Banner/banner";
import {RegistrationForm} from "./FormRegistration/RegisterForm";
import {DocumentList} from "../../Public/DevisPage/DocumentList";

const RegistrationPage = () => {
    return (
        <>
            <Navbar />
            <Banner />
            <RegistrationForm/>
            <DocumentList />
            <Footer />
        </>
    );
};

export default RegistrationPage;