import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

export const Select = ({
  disabled,
  label,
  value,
  labelClassName,
  options,
  selectedClassName,
  onChange,
  name,
}) => {

  return (
    <div className="w-full">
      {label && (
        <label className={`${labelClassName} block mb-1 text-sm font-medium text-gray-900`}>
          {label}
        </label>
      )}
      <select
        disabled={disabled}
        className={`${selectedClassName} bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
        defaultValue={value}
        onChange={onChange}
        name={name}
      >
        {options.map(({ name, value }, key) => {
          return (
            <option key={key} value={value}>
              {_.capitalize(name)}
            </option>
          );
        })}
      </select>
    </div>
  );
};

Select.propTypes = {
  width: PropTypes.number,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  options: PropTypes.array,
  selectedClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

Select.defaultProps = {
  width: 150,
  disabled: false,
  label: "",
  labelClassName: "",
};
