import React from "react";
import { Link } from "react-router-dom";
import { Images } from "../../assets";

export const Left = () => {
  return (
    <div className="w-[550px]">
      <img src={Images.SchengenLogo} alt="schengen-logo" className="mb-5"/>
      <p className="mb-2">
        Assurance Voyage Schengen, votre courtier spécialiste des assurances
        voyage, vous propose des contrats parmi les meilleurs rapports
        qualité/prix du marché.
      </p>
      <p className="mb-2">
        Fort d'une grande expérience dans l'accompagnement des voyageurs
        souhaitant venir en France et dans les pays de l'espace Schengen, nous
        vous conseillons et vous proposons différents niveau de garanties pour
        assurer votre voyage dans l'union Européene.
      </p>
      <div>
        <div>
          <Link to="/" className="text-blue-primary underline">https://assurance-voyage-schengen.com</Link>
          <span> - Tout droits réservés 2022</span>
        </div>
        <div>
          <Link to="/" className="text-blue-primary underline">CGV</Link>
          <span> - </span>
          <Link to="/" className="text-blue-primary underline">Mentions légales</Link>
          <span> - </span>
          <Link to="/" className="text-blue-primary underline">Confidentialités des données</Link>
        </div>
      </div>
    </div>
  );
};
