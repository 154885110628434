import React from "react";
import styles from "./style.module.scss";

export const SixBanner = () => {
  return (
    <div className="bg-blue-primary pl-[50px]">
      <div className={`${styles.flagBg} w-full h-full py-14`}>
        <div className="">
          <h3 className="text-3xl font-bold text-yellow-secondary">
            Grands voyageurs ?
          </h3>
          <p className="text-3xl text-white mb-2">
            Découvrez notre formule{" "}
            <span className="font-bold">Multi Trip</span>
          </p>
          <p className="text-white mb-2">
            Une offre d'assurance Schengen pour vous permettre de voyage en
            toute serenité tout au long de l'année.
          </p>
          <p className="text-white">
            Notre formule <span className="font-bold">Multi Trip</span> comprend
            :
          </p>
          <p className="text-white mt-2">- l'assistance rappatriement</p>
          <p className="text-white">- les frais médicaux</p>
          <p className="text-white my-2">
            Et toujours à des tarifs très bon marchés avec un premier prix à
            partir de :
          </p>
          <div className="flex justify-between w-[500px] items-center mt-5">
            <div className="bg-blue-secondary rounded-full h-[50px] px-5 py-2.5 text-center">
              <span className="font-bold text-2xl text-white uppercase h-full text-center">230,00€/an</span>
            </div>
            <div className="bg-yellow-primary rounded-full h-[50px] px-5 py-2.5 text-center">
              <span className="text-2xl uppercase text-blue-secondary h-full">Votre devis en ligne</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
