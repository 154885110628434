import Axios from './caller.service'

/**
 * Récupération de command
 * @param {email} email 
 * @returns {Promise}
 */
let getCredentialByEmail = (email) => {
    return Axios.post('/credential/email', email)
}

/**
 * update personal user data
 * @param {user} user 
 * @returns {Promise}
 */
let updateUserInfo = (user) => {
    return Axios.put('/user/update', user)
}

const userService = {
    getCredentialByEmail, updateUserInfo
}

// Déclaration des services pour import
export default userService;