import React from "react";
import { NavBar } from "./NavBar";

export const Navbar = () => {

  return (
    <>
      <nav className="w-full px-12 h-[120px]">
        <NavBar />
      </nav>
    </>
  );
};
