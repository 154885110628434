import React from "react";
import styles from "./style.module.scss";

export const Loader = () => {
    return (
        <>
        <span className={`${styles.loader}`}></span>
        </>
    );
};
