import { useFormik } from "formik";
import React, { useEffect } from "react";
import { LoginValidationSchema } from "./_const";
import {ScButton} from "../../../../components/ScButton";
import _ from "lodash";
import {NavLink, useNavigate} from "react-router-dom";
import accountService from '../../../../_services/account.service';


export const LoginForm = () => {
  const navigate = useNavigate();

  useEffect(()=>{
    if(localStorage.getItem('token') !== "" && localStorage.getItem('token') !== null){
      navigate("/dashboard");
    }
  },[navigate])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: (values) => {
      accountService.login(values).then((r) => {
        accountService.saveToken(r.data.apiToken);
        navigate("/dashboard");
      }).catch((e) => {
        console.log(e, " <= error ")
        if (e.response.data.errors !== undefined) {
          console.log(e);
          // setValidationErrors(e.response.data.errors);
        }
        if (e.response.data.error !== undefined) {
          // setValidationErrors(e.response.data.error);
        }
      });
    },
    validationSchema: LoginValidationSchema,
  });

  return (
      <form className="p-12 flex items-center justify-center">
        <div className="border-b border-gray-900/10 pb-12 border shadow rounded-lg p-5 w-4/12">
          <div>
            <p className="text-xl font-bold">Connexion au compte client</p>
            <p className="my-2">
              Veuillez saisir ces informations afin de vous connecter à votre compte client :
            </p>
          </div>
          <div className="mt-5 grid grid-cols-1 gap-y-8 sm:grid-cols-4 ">
            <div className="sm:col-span-4">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Adresse émail :
              </label>
              <div className="mt-2">
                <input
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="family-name"
                    onChange={formik.handleChange}
                    className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              {formik.touched.email && formik.errors.email ? (
                  <div className="text-red-700">{formik.errors.email}</div>
              ) : null}
            </div>

            <div className="sm:col-span-4">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Mot de passe :
              </label>
              <div className="mt-2">
                <input
                    type="password"
                    name="password"
                    id="password"
                    autoComplete="family-name"
                    onChange={formik.handleChange}
                    className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              {formik.touched.password && formik.errors.password ? (
                  <div className="text-red-700">{formik.errors.password}</div>
              ) : null}
            </div>
          </div>
          <p className="my-2">
          </p>
          <NavLink to="/auth/register">
            <p className="text-blue-primary font-bold text-[14px] leading-[20px]">Créer un nouveau compte</p>
          </NavLink>
          <div className="flex justify-center mt-10">
            <ScButton
                type="primary"
                children={_.upperCase("Valider")}
                className="font-bold w-[150px]"
                onClick={formik.submitForm}
            />
          </div>
        </div>
      </form>
  );
};
