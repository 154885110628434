
export const Selection = ({ dayDiff, option, price, totalPersonne, nbrOldPeople }) => {
  return (
    <div className="bg-yellow-secondary text-blue-secondary w-[300px] h-[200px] mt-4 shadow rounded-r-lg p-5 flex flex-col justify-around">
      <p className="font-bold">Votre sélection</p>
      <p className="font-bold text-blue-primary"> {totalPersonne} personne et {nbrOldPeople} de plus de 79 ans</p>
        <p className="font-bold text-blue-primary">Les options selectionnée :</p>
        <p className="font-bold">{option}{option.length < 1 && "Aucune option selectionné"}</p>
        <div className="flex justify-between">
        <p>Durée du contrat :</p>
        <p>{dayDiff < 0 ? "0" : dayDiff} jours</p>
      </div>
      <div className="flex justify-between font-bold text-lg border-t-4 border-blue-secondary">
        <p>
          Total à payer :
        </p>
        <p className="text-blue-primary">{price} €</p>
      </div>
    </div>
  );
};
