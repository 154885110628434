import {FormContract} from "../../../../components/FormContract";
import React from "react";
import {contractValidationSchema} from "./_const";
import {useNavigate} from "react-router-dom";
import commandService from "../../../../_services/command.service";
import { useState } from "react";

const ContractCreateContent = (props) => {
    const { id } = props;
    const navigate = useNavigate();
    const [nbContract, setNbContract] = useState(1);
    const [globalError, setGlobalError] = useState([]);
    const [accordions, setAccordion] = useState([
        {
            key: 0,
            gender: 'male',
            firstname: '',
            lastname: '',
            nationality: '',
            countryReside: '',
            born: '',
            speaking: 'Afghanistan',
            numberPass: '',
            expirPass: '',
            objectifTrip: '',
            email: '',
            profession: '',
            addressReside: '',
            dateStart: '',
            dateEnd: '',
            speakCertif: 'Afghanistan',
            destination: 'Afghanistan',
            contractType: 'Schengen',
            fifthteenDay: false,
            repaymentVisa: false,
            responsabilityCivil: false,
            noFranchise: false,
            multiTrip: false,
            rgpd: true,
            isOpen: false
        }, 
    ]);
    let command = {
        userUUID: id,
        numberContract: nbContract,
        objectTrip: '',
        methodPaiement: '',
        amount: '',
        contractType: 'Schengen',
        promoCode: '',
        contracts: []
    }

    const toggleAccordion = async (accordionkey) => {
        const updatedAccordions = accordions.map((accord) => {
            if (accord.key === accordionkey) {
                return { ...accord, isOpen: !accord.isOpen };
            } else {
                return { ...accord, isOpen: false };
            }
        });
        setAccordion(updatedAccordions); 
    };
    const listenErrorFormContract = (key, state) => {
        if(state){
            if(!globalError.includes(key)){
                setGlobalError([...globalError, key])
            }
        }else{
            const err = globalError.filter(el => el !== key)
            setGlobalError(err);
        }
        console.log(globalError)
    }
    const checkFirstFormInit = () => {
        accordions.forEach((el) => {
            contractValidationSchema().validate(el, { abortEarly: false })
            .then((res) => {
                console.log(res);
                listenErrorFormContract(el.key, false);
            }).catch(err => {
                console.log(err);
                listenErrorFormContract(el.key, true);
            });
        })
    }

    const submitFormCommand = () => {
        checkFirstFormInit();
        if(globalError.length === 0){
            command.contracts = accordions
            command.objectTrip = accordions[0].objectifTrip
            commandService.addCommand(command).then(r => {
                navigate("/dashboard/commande/recapitulatif/" + r.data.id);
            }).catch(err => {
                console.log(err)
            })
        }
    }
    const deleteFormContract = (accordionkey) => {
        let accordionsWithoutDeletedElement = accordions.filter(a => a.key !== accordionkey);
        let arraySorted = accordionsWithoutDeletedElement.map((value, key) => {
            value.key = key;
            return value
        });
        setNbContract(nbContract - 1);
        setAccordion(arraySorted); 
    }
    const handleChangeFormContrat = (id, key, data) => {
        console.log(id, key, data);
        accordions.forEach((accord) => { 
            console.log(accord)
            if (accord.key === id) {
                accord[key] = data;
            }
        });  
    }
    const addContract = () => {
        setNbContract(nbContract + 1);
        setAccordion([...accordions, { 
            key: nbContract,
            gender: 'male',
            firstname: '',
            lastname: '',
            nationality: '',
            countryReside: '',
            born: '',
            speaking: 'Afghanistan',
            numberPass: '',
            expirPass: '',
            objectifTrip: '',
            email: '',
            profession: '',
            addressReside: '',
            dateStart: '',
            dateEnd: '',
            speakCertif: 'Afghanistan',
            destination: 'Afghanistan',
            contractType: 'Schengen',
            fifthteenDay: false,
            repaymentVisa: false,
            responsabilityCivil: false,
            noFranchise: false,
            multiTrip: false,
            rgpd: true,
            isOpen: false
        }]);
    }

    return (
        <div className='px-[25px] pt-[25px] bg-[#F8F9FC] pb-[40px]'>
            <div className='flex items-center justify-between'>
                <h1 className='text-[28px] leading-[34px] font-normal text-[#5a5c69] cursor-pointer'>Dashboard</h1>
            </div>
            <div className='flex mt-[22px] w-full gap-[30px]'>
                <div className='basis-[100%] border bg-white shadow-md cursor-pointer rounded-[4px]'>
                    <div
                        className='bg-[#F8F9FC] flex items-center justify-between py-[15px] px-[20px] border-b-[1px] border-[#EDEDED] mb-[20px]'>
                        <h1 className='text-[#4e73df] text-[16px] leading-[19px] font-bold'>Souscription</h1>
                    </div>
                    {accordions.map((accordion) => ( 
                        <FormContract 
                            key={accordion.key} 
                            mykey={accordion.key} 
                            isOpen={accordion.isOpen}
                            isDeleted={() => { return (accordions.length < 2) ? false : true}}
                            handleChangeFormContrat={handleChangeFormContrat}
                            deleteAccordion={() => deleteFormContract(accordion.key)} 
                            toggleAccordion={() => toggleAccordion(accordion.key)}
                            listenErrorFormContract={listenErrorFormContract}
                        /> 
                    ))}
                </div>
            </div>
            <div className="flex justify-start mt-10">
                <button
                    className="btnAddContract font-bold bg-blue-secondary text-yellow-primary"
                    type="button"
                    onClick={addContract}>
                        +
                </button>
            </div>
            {globalError.length !== 0 ? (
                    <div className="text-red-700">Certain formulaire ne sont pas bien rempli</div>
                ) : null}
            <div className="flex justify-center mt-10">
                <button 
                    className="focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center bg-blue-secondary text-yellow-primary " 
                    type="button" 
                    onClick={submitFormCommand}>
                        Valider ma demande de contrat
                </button>
            </div>
        </div>
    );
}

export default ContractCreateContent;
