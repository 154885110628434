import { Navigate } from "react-router-dom";
import accountService from "../_services/account.service";

/**
 * Fonction de vérification de token
 * Et fermeture partie admin
 * 
 * @param {Object} props{children} 
 * @returns {ReactNode}
 */
const AuthGuard = ({children}) => {

    if(!accountService.isLogged()){
        return <Navigate to="/auth"/>
    }
   
    return children
};

export default AuthGuard;




// import { createContext, useState } from "react";
// 
// const AuthContext = createContext();
// 
// const AuthProvider = ({children}) => {
//     const [auth, setAuth] = useState({});
//     return (
//         <AuthContext.Provider value = {{auth, setAuth}}>
//             {children}
//         </AuthContext.Provider>
//     )
// }
// 
// export default { AuthContext, AuthProvider };
