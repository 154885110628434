import { useEffect, useState } from "react";
import { format } from "date-fns";
import { NavLink } from "react-router-dom";
import { Loader } from '../../../components/Loader';
import { ModalFormUser } from  "../../../components/ModalFormUser";

const ProfileContent = (props) => {
    const {loading, user, email} = props;
    const [modalOpen, setModalOpen] = useState(false);
    const sexe = (user.gender === "male") ? "Monsieur" : "Madame";

    const handleButtonClick = () => {
        setModalOpen(false);
    };

    return (
        <div className='px-[25px] pt-[25px] bg-[#F8F9FC] pb-[40px]'>
            <div className='flex items-center justify-between'>
                <h1 className='text-[28px] leading-[34px] font-normal text-[#5a5c69]'>Dashboard</h1>
            </div>
            <div className="flex">
                <div className='flex mt-[22px] w-6/12 gap-[30px]'>
                    <div className='basis-[100%] border bg-white shadow-md rounded-[4px]'>
                        <div className='bg-[#F8F9FC] flex items-center justify-between py-[15px] px-[20px] border-b-[1px] border-[#EDEDED] mb-[20px]'>
                            <h2 className='text-[#4e73df] text-[16px] leading-[19px] font-bold'>Mes Informations personnels</h2>
                            <h2 onClick={() => setModalOpen(true)} className='text-[#4e73df] text-[10px] leading-[19px] underline cursor-pointer font-bold'>Modifier mes informations</h2>
                        </div>
                        {loading ? <div className="p-12 w-full flex justify-around items-center"><Loader /></div> : <><div className="w-full">
                            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 border-b border-gray-900/10 pb-12 shadow rounded-lg p-5">
                                <div className="sm:col-start-1 col-end-1">
                                    <label htmlFor="genre" className="block text-sm font-medium leading-6 text-gray-900">
                                        {sexe}
                                    </label>
                                </div>
                                <div className="sm:col-span-2">
                                    <label htmlFor="first-name"
                                        className="block text-sm font-medium leading-6 text-gray-900">
                                        Nom: {user.lastname}
                                    </label>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="first-name"
                                        className="block text-sm font-medium leading-6 text-gray-900">
                                        Prénom: {user.firstname}
                                    </label>
                                </div>
                                <div className="sm:col-span-3">

                                    <label htmlFor="last-name"
                                        className="block text-sm font-medium leading-6 text-gray-900">
                                        Profession: {user.occupation}
                                    </label>
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor="last-name"
                                        className="block text-sm font-medium leading-6 text-gray-900">
                                        Téléphone: {user.phoneNumber}
                                    </label>
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor="first-name"
                                        className="block text-sm font-medium leading-6 text-gray-900">
                                        Adresse email: {email}
                                    </label>
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor="first-name"
                                        className="block text-sm font-medium leading-6 text-gray-900">
                                        Date de naissance: {format(new Date(user.born), "dd/MM/yyyy")}
                                    </label>
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor="first-name"
                                        className="block text-sm font-medium leading-6 text-gray-900">
                                        Pays de nationalité: {user.nationality}
                                    </label>
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor="first-name"
                                        className="block text-sm font-medium leading-6 text-gray-900">
                                        Pays de residence: {user.countryResidence}
                                    </label>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="last-name"
                                        className="block text-sm font-medium leading-6 text-gray-900">
                                        N° de passport: {user.numberPassport}
                                    </label>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="first-name"
                                        className="block text-sm font-medium leading-6 text-gray-900">
                                        Date d'éxpiration du passport: {format(new Date(user.expirPassport), "dd/MM/yyyy")}
                                    </label>
                                </div>
                                <div className="col-span-full">
                                    <label htmlFor="street-address"
                                        className="block text-sm font-medium leading-6 text-gray-900">
                                        Adresse de résidence: {user.addressResidence}
                                    </label>
                                </div>
                            </div>
                        </div>
                        </>}
                    </div>
                </div>
                <div className='flex mt-[22px] w-2/12 gap-[30px] pl-10'>
                    <div className='basis-[100%] border bg-white shadow-md rounded-[4px]'>
                        <div className='bg-[#F8F9FC] flex items-center justify-between py-[15px] px-[20px] border-b-[1px] border-[#EDEDED] mb-[20px]'>
                            <h2 className='text-[#4e73df] text-[16px] leading-[19px] font-bold'>Action rapide</h2>
                        </div>
                        <div className="flex flex-col px-5">
                            <button className="focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 my-5 text-center bg-yellow-primary text-blue-secondary">
                                <NavLink to="./commande/create">
                                    Souscrire à un contract
                                </NavLink>
                            </button>
                            <button className="focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center bg-blue-secondary text-yellow-primary">
                                <NavLink to="./sinistre/command">
                                    Déclarer un sinistre
                                </NavLink>
                            </button>
                            <button className="focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 mt-20 text-center bg-gray-300 text-blue-secondary">
                                <NavLink to="./support">
                                    Faire une réclamation
                                </NavLink>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {modalOpen &&
                <ModalFormUser
                    closeModal={handleButtonClick}
                    onCancel={handleButtonClick}
                    user={user}
                />
            }
        </div>
    );
}

export default ProfileContent;