import React from "react";
import { Footer } from "../../../components/Footer";
import { Navbar } from "../../../components/Navbar";
import {Banner} from "../../../components/Banner/banner";
import { InfoContent } from "./Content/InfoContent";
import {DocumentList} from "../DevisPage/DocumentList";

const GarantiPage = () => {
    return (
        <>
            <Navbar />
            <Banner />
            <InfoContent />
            <DocumentList />
            <Footer />
        </>
    );
};

export default GarantiPage;