import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ScButton } from "../../../../../components/ScButton";
import { formulesDetails } from "./_const";

export const Card = ({data}) => {

  const cardClass = classNames("w-[300px] h-[500px] p-7", {
    "rounded-l-lg bg-grey-primary": data.gamme === "basics",
    "rounded-r-lg bg-grey-primary": data.gamme === "full",
    "rounded-lg  bg-gradient-to-b from-yellow-secondary h-[550px] drop-shadow-xl": data.gamme === "middle",
  });

  const priceBlockClass = classNames(
    "w-[150px] h-[50px] mb-3 flex items-center rounded-full flex justify-center",
    {
      "bg-blue-primary": data.gamme !== "middle",
      "bg-blue-secondary": data.gamme === "middle",
    }
  );

  const priceTextClass = classNames("text-white font-bold text-xl", {
    "text-yellow-primary": data.gamme === "middle",
  });

  return (
    <div className={cardClass}>
      <div className="h-full flex flex-col justify-between">
        <div className="flex items-center justify-center pt-3 flex-col text-center">
          <h2 className="text-blue-secondary text-3xl font-bold">Formule</h2>
          <h2 className="text-blue-secondary text-xl font-bold">{data.title}</h2>
        </div>
        <div className="text-center pt-3">
          <span className="text-blue-primary">{data.from}</span>
          <span>{data.gamme !== "full" && <br />} à partir de :</span>
        </div>
        <div className="flex justify-center pt-3 ">
          <div className={priceBlockClass}>
            <span className={priceTextClass}>{data.price}</span>
          </div>
        </div>
        <div>
          <ul className="list-disc">
            {_.map(formulesDetails, ({key, info, form}) => {
              if (data.gamme === "basics" && form === data.gamme) return <li key={key} className="text-xs leading-4	">{info}</li>
              if (data.gamme === "middle" && form !== "full") return <li key={key} className={`${form === "middle" && "text-black font-bold"} text-xs`}>{info}</li>
              if (data.gamme === "full") return <li key={key} className={`${form !== "basics" && "text-black font-bold"} text-xs`}>{info}</li>
            })}
          </ul>
        </div>
        <div className="w-full flex items-center justify-around">
          <div className="text-center">
            <span className="font-bold underline text-xs">En savoir plus</span>
          </div>
          <div>
            <ScButton
              type="primary"
              children="Devis en ligne"
              className="font-bold uppercase"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  gamme: PropTypes.string,
  from: PropTypes.string,
  option: PropTypes.string,
  price: PropTypes.string,
};

Card.defaultProps = {
  height: "500px",
  width: "300px",
};
