import React from "react";
import { Icons } from "../../../../assets";

export const ThirdBanner = () => {
  const renderInfo = [
    {
      id: 1,
      icon: Icons.Planet,
      message: "Vous avez besoin d'une assurance Schengen ?",
    },
    {
      id: 2,
      icon: Icons.Contrat,
      message: "Souscrivez votre assurance voyage en ligne",
    },
    {
      id: 3,
      icon: Icons.Attestation,
      message: "Obtenez votre attestation d'assurance voyage Schengen",
    },
    {
      id: 4,
      icon: Icons.Map,
      message: "Déposez votre certificat lors de votre demande de visa",
    },
  ];

  return (
    <div className="w-full bg-blue-primary flex justify-around items-center py-[30px]">
      <div className="w-[1000px] text-center">
        <p className="text-3xl font-bold text-white">L'assurance visa Schengen</p>
        <p className="text-4xl font-bold mb-10 text-yellow-secondary">
          simple & rapide
        </p>
        <div className="flex justify-center">
          {renderInfo.map((value, key) => {
            return (
              <div key={key} className="w-[300px]">
                <div className="mb-3">
                  <div className="flex items-center justify-center">
                    <div className="mr-3 w-10 h-10 rounded-full bg-yellow-secondary items-center flex justify-center text-xl font-bold text-blue-primary">
                      {value.id}
                    </div>
                    <img src={value.icon} alt="img" />
                  </div>
                  <span className="text-yellow-secondary">{value.message}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
