import React from 'react'
import { Link } from 'react-router-dom'

export const Right = () => {
  return (
    <div className="w-[425px]">
      <div>
        <h3 className="mb-2 text-xl font-bold">Suivez-nous sur les réseauc sociaux</h3>
      </div>
      <div>
        <h3 className="mb-2 text-xl font-bold">Autres produits d'assurance du groupe</h3>
        <div>
          <Link to="/" className="text-blue-primary underline">
            Assurances Voyage Mondiale
          </Link>
        </div>
        <div>
          <Link to="/" className="text-blue-primary underline">
            Assurances Voyage Sports Extrêmes
          </Link>
        </div>
      </div>
    </div>
  )
}
