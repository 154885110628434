import React from "react";
import { renderAvantage, renderPrecision } from "./_const";

export const FourthBanner = () => {
  return (
    <div className="w-full h-min[350px] bg-grey-primary flex justify-around items-center py-[30px] px-12">
      <div className="w-full flex justify-around">
        <div className="w-6/12">
          <h3 className="text-2xl mb-3 font-bold">Nos avantages avec votre Assurance Voyage Schengen</h3>
          {renderAvantage.map((value, key) => (
            <div key={key} className="flex">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#ffc900"
                  className="w-6 h-6 mr-0.5"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div>
                <span className="text-blue-primary text-base">
                  {value.message}
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="w-6/12 pl-6">
        <h3 className="text-2xl mb-3 font-bold">Quelques précisions sur l'Assurance Voyage Schengen</h3>
          {renderPrecision.map((value, key) => (
            <div key={key} className="w-full bg-blue-primary h-[40px] mb-3 flex items-center pl-3 rounded-full">
              <span className="text-white font-bold text-base">{value.message}</span>
            </div>
          ))}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};
