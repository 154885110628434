import React from "react";
import { ScButton } from "../ScButton";
import { navBarText } from "./_text";
import { Images } from "../../assets";
import {Link, NavLink} from "react-router-dom";

export const NavBar = () => {
  return (
    <div className="list-none flex h-full justify-between">
        <div className="flex items-center">
        <Link to="/">
            <img src={Images.SchengenLogo} alt="schengen-logo" />
        </Link>
      </div>
      <div className="w-[56rem] flex justify-around items-center">
          <NavLink
              to="/"
              className={({ isActive }) =>
                  isActive ? "text-yellow-500" : "text-blue-primary font-bold"
              }
          >
              <p>Accueil</p>
          </NavLink>
          <NavLink
              to="/information"
              className={({ isActive }) =>
                  isActive ? "text-yellow-500" : "text-blue-primary font-bold"
              }
          >
              Visa Schengen
          </NavLink>
          <NavLink
              to="/garanti"
              className={({ isActive }) =>
                  isActive ? "text-yellow-500" : "text-blue-primary font-bold"
              }
          >
              <p>Guide et conseils</p>
          </NavLink>
          <NavLink
              to="/devis"
              className={({ isActive }) =>
                  isActive ? "text-yellow-500" : "text-blue-primary font-bold"
              }
          >
              <p>Devis</p>
          </NavLink>
          <NavLink
              to="/auth/partner/login"
              className={({ isActive }) =>
                  isActive ? "text-yellow-500" : "text-blue-primary font-bold"
              }
          >
              <p>Partenaire</p>
          </NavLink>
          <a
              href="tel:+33 1 23 45 67 89"
              className="text-blue-primary font-bold"
          >
              <p>+33 1 23 45 67 89</p>
          </a>
        <div className="w-[140px]">
          <Link to="/auth">
            <ScButton
              type="primary"
              children={navBarText.spaceCustomer}
              className="font-bold"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};
