import React from "react";
import "./index.scss";

export const Modal = ({ closeModalValid, closeModalCanceled, closeModal, message, twoBtn} ) => {
    return (
        <div
            className="modal-container"
            onClick={(e) => {
                if (e.target.className === "modal-container")
                    closeModal();
            }}>
            <div className="modal">
                <div className="modal-header"
                    onClick={() => closeModal()}>
                    <p className="text-[#4e73df] text-[16px] leading-[19px] font-bold">Information</p>
                    <p className="close">&times;</p>
                </div>
                <div className="modal-content">
                    <p className="text-center">{message}</p>
                </div>
                <div className="modal-footer">
                    { twoBtn ?
                        <>
                            <button
                                type="submit"
                                className="focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center bg-red-600 text-white "
                                onClick={() => closeModalCanceled()}>
                                Annuler
                            </button>
                            <button
                                type="submit"
                                className="focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center bg-blue-secondary text-yellow-primary "
                                onClick={() => closeModalValid()}>
                                Valider
                            </button>
                        </> : 
                        <button
                            type="submit"
                            className="focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center bg-blue-secondary text-yellow-primary "
                            onClick={() => closeModal()}>
                            Valider
                        </button>
                    }
                </div>
            </div>
        </div>
    );
}

export default Modal;