import Axios from './caller.service'

/**
 * Récupératoin de la liste des contracts
 * @returns {Promise}
 */
let getAllContracts = () => {
    return Axios.get('/contract')
}

/**
 * Récupération d'un contract
 * @param {number} id 
 * @returns {Promise}
 */
let getContract = (id) => {
    return Axios.get('/contract/' + id)
}

/**
 * Ajout d'un contract
 * @param {number} contract 
 * @returns {Promise}
 */
let addContract = (contract) => {
    return Axios.put('/contract', contract)
}

/**
 * Mise à jour d'un contract
 * @param {number} contract 
 * @returns {Promise}
 */
let updateContract = (contract) => {
    return Axios.patch('/contract/'+ contract.id, contract)
}

/**
 * Suppression d'un contract
 * @param {number} id 
 * @returns {Promise}
 */
let deleteContract = (id) => {
    return Axios.delete('/contract/'+id)
}

// Décaraltion des esrvices pour import
const contractService = {
    getAllContracts, getContract, addContract, updateContract, deleteContract
}

export default contractService;