import contractService from "../../../../_services/contract.service";
import { Loader } from "../../../../components/Loader";
import { BtnOption } from "../../../../components/BtnOption";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { ArrowUpTrayIcon } from '@heroicons/react/24/solid'
import sinisterService from '../../../../_services/sinister.service'


const SinisterCreated = () => {
    const contractId = useParams().id;
    const [sinister, setSinister] = useState({contractUUID: contractId, description: "", type: "Frais de santé", file: new FormData()});
    const [files, setFiles] = useState([]);
    const [rejected, setRejected] = useState([]);
    const [toggle, setToggle] = useState([true, false, false]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        contractService.getContract(contractId).then(r => {
            console.log(r);
            setLoading(false);
        }).catch(err => {
            console.log(err)
        });
    }, [contractId]);

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
      if (acceptedFiles?.length) {
        setFiles(previousFiles => [
          ...previousFiles,
          ...acceptedFiles.map(file =>
            Object.assign(file, { preview: URL.createObjectURL(file) })
          )
        ])
      }
  
      if (rejectedFiles?.length) {
        setRejected(previousFiles => [...previousFiles, ...rejectedFiles])
      }
    }, [])
  
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      maxFiles:2,
      accept: {
        'image/*': ['.jpeg', '.png', '.pdf']
      },
      maxSize: 1024 * 1000,
      onDrop
    })
  
    useEffect(() => {
      // Revoke the data uris to avoid memory leaks
      return () => files.forEach(file => URL.revokeObjectURL(file.preview))
    }, [files])
  
    const removeAll = () => {
      setFiles([])
      setRejected([])
    }
    const removeRejected = name => {
      setRejected(files => files.filter(({ file }) => file.name !== name))
    }
    const handleSubmit = async e => {
      e.preventDefault()
      if (!files?.length) return

      const formData = new FormData();
      formData.append("contractUUID", sinister.contractUUID);
      formData.append("description", sinister.description);
      formData.append("type", sinister.type);
      console.log(files);
      files.forEach(file => {
        formData.append("file", file);
      });

      sinisterService.getCreateSinister(formData).then(r => {
        console.log(r);
      }).catch(err => {
          console.log(err)
      });
    }

    const handleChangeSinisterDescription = (e) => {
        setSinister({
            ...sinister,
            [e.target.name]: e.target.value,
        });
    }

    const handleChangeSinisterType = (value) => {
        setSinister({
            ...sinister,
            type: value,
        });
    }

    return (
        <div className='px-[25px] pt-[25px] bg-[#F8F9FC] pb-[40px]'>
            <div className='flex items-center justify-between'>
                <h1 className='text-[28px] leading-[34px] font-normal text-[#5a5c69] cursor-pointer'>Dashboard</h1>
            </div>
            <div className='flex mt-[22px] w-full gap-[30px]'>
                <div className='basis-[100%] border bg-white shadow-md cursor-pointer rounded-[4px]'>
                    <div className='bg-[#F8F9FC] flex items-center justify-between py-[15px] px-[20px] border-b-[1px] border-[#EDEDED] mb-[20px]'>
                        <h2 className='text-[#4e73df] text-[16px] leading-[19px] font-bold'>Selectionner le type de sinistre</h2>
                    </div>
                    {loading ? <div className="p-12 w-full flex justify-around items-center"><Loader /></div> :
                        <div className="overflow-hidden rounded-lg shadow-xs">
                            <div className="flex justify-around mb-5 mt-2">
                                <BtnOption
                                    active={toggle[0]}
                                    onClick={(e) => {
                                        setToggle([true, false, false]);
                                        handleChangeSinisterType("Frais médicaux de santé");
                                    }}
                                    children={"Frais médicaux / Frais d'hospitalisation"} />
                                <BtnOption
                                    active={toggle[1]}
                                    onClick={(e) => {
                                        setToggle([false, true, false]);
                                        handleChangeSinisterType("Frais dentaire");
                                    }}
                                    children={"Frais dentaire"} />
                                <BtnOption
                                    active={toggle[2]}
                                    onClick={(e) => {
                                        setToggle([false, false, true]);
                                        handleChangeSinisterType("Assistance rapatriement");
                                    }}
                                    children={"Assistance rapatriement"} />
                            </div>
                            {sinister.type !== "Assistance rapatriement" ?
                                <>
                                    <div className='flex mt-[22px] w-full gap-[30px]'>
                                        <div className='basis-[100%] border bg-white shadow-md cursor-pointer rounded-[4px]'>
                                            <div className='bg-[#F8F9FC] flex items-center justify-between py-[15px] px-[20px] border-b-[1px] border-[#EDEDED]'>
                                                <h2 className='text-[#4e73df] text-[16px] leading-[19px] font-bold'>Descriptif du sinistre</h2>
                                            </div>
                                            <div className="w-full">
                                                <div className="max-w-2xl mx-auto mb-[20px] mt-[20px]">
                                                    <textarea onChange={handleChangeSinisterDescription} id="description" name="description" value={sinister.description} rows="12" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Décriver les conditions de votre sinistre..."></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='bg-[#F8F9FC] flex items-center justify-between py-[15px] px-[20px] border-b-[1px] border-[#EDEDED] mb-[20px]'>
                                        <h1 className='text-[#4e73df] text-[16px] leading-[19px] font-bold'>Pièce a fournir :</h1>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div {...getRootProps()} style={{ border: '2px dashed #0087F7', padding: '20px', cursor: 'pointer' }}>
                                            <input {...getInputProps()} />
                                            <div className='flex flex-col items-center justify-center gap-4'>
                                                <ArrowUpTrayIcon className='w-5 h-5 fill-current' />
                                                {isDragActive ? (
                                                    <p>Glisser le fichier ici...</p>
                                                ) : (
                                                    <p>Glisser, déposer vos documents ici, ou cliquer pour selectionner les documents...</p>
                                                )}
                                                <em>(2 fichier maximum)</em>
                                            </div>
                                        </div>
                                        {/* Preview */}
                                        <section className='mt-10'>
                                            {files.length ? 
                                            <>
                                                <div className='flex gap-4'>
                                                    <button type='button' onClick={removeAll} className='mt-1 text-[12px] uppercase tracking-wider font-bold text-neutral-500 border border-secondary-400 rounded-md px-3 hover:bg-secondary-400 hover:text-white transition-colors'>
                                                        Retirer tout les fichier
                                                    </button>
                                                </div>
                                                {/* Accepted files */}
                                                <h3 className='title text-lg font-semibold text-neutral-600 mt-10 border-b pb-3'>Fichier Accepté <em>(.jpeg, .png, .pdf)</em></h3>
                                                <ul className='mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-10'>
                                                    {files.map(file => (
                                                        <li key={file.name}>
                                                            {file.name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </> : null}

                                            {/* Rejected Files */}
                                            {rejected.length ?
                                            <>
                                                <h3 className='title text-lg font-semibold text-neutral-600 border-b pb-3'>Fichier refusé</h3>
                                                <ul className='mt-6 flex flex-col'>
                                                    {rejected.map(({ file, errors }) => (
                                                        <li key={file.name} className='flex items-start justify-between'>
                                                            <div>
                                                                <p className='mt-2 text-neutral-500 text-sm font-medium'>
                                                                    {file.name}
                                                                </p>
                                                                <ul className='text-[12px] text-red-400'>
                                                                    {errors.map(error => (
                                                                        <li key={error.code}>Votre document a été refusé mauvais format</li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                            <button
                                                                type='button'
                                                                className='mt-1 py-1 text-[12px] uppercase tracking-wider font-bold text-neutral-500 border border-secondary-400 rounded-md px-3 hover:bg-secondary-400 hover:text-white transition-colors'
                                                                onClick={() => removeRejected(file.name)}
                                                            >
                                                                retirer
                                                            </button>
                                                        </li>
                                                    ))}
                                                </ul></> : null}
                                            <button
                                                type="submit"
                                                className="p-12 flex justify-around items-center focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center bg-blue-secondary text-yellow-primary ">
                                                Valider
                                            </button>
                                        </section>
                                    </form>
                                </> :
                                <>
                                    <div className='bg-[#F8F9FC] flex items-center justify-between py-[15px] px-[20px] border-b-[1px] border-[#EDEDED] mb-[20px]'>
                                        <h1 className='text-[#4e73df] text-[16px] leading-[19px] font-bold'> Assistance rapatriement : </h1>
                                    </div>
                                    <p>Merci de contacter</p>
                                    <p>Mutuaide Assistance</p>
                                    <p>01.43.51.xx.xx</p>
                                </>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default SinisterCreated;