import * as Yup from "yup";
import { parse } from 'date-fns';

export const registrationValidationSchema = () => {
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    return Yup.object({
        gender: Yup.mixed().oneOf(['male', 'female']).defined(),
        lastname: Yup.string()
            .matches(/[a-zA-Z]/, 'Votre nom ne peut contenir que des caractère alphabétique.')
            .required('Vous devez renseigner votre nom.'),
        firstname: Yup.string()
            .matches(/[a-zA-Z]/, 'Votre prenom ne peut contenir que des caractère alphabétique.')
            .required('Vous devez renseigner votre prenom.'),
        born: Yup.date()
            .transform(function (value, originalValue) {
                if (this.isType(value)) return value;
                return parse(originalValue, "dd/MM/yyyy", new Date());
            })
            .typeError("Une date valide")
            .max("2005-12-31", "Vous devez etre majeur")
            .required(),
        phone: Yup.string().matches(phoneRegExp, 'Le numéro de téléphone invalid').required('Vous devez renseigner numéro de téléphone.'),
        email: Yup.string().email('Votre adresse email est invalide').required('Email requis'),
        occupation: Yup.string().required("Votre profession doit etre renseigné "),
        nationality: Yup.string().required("La nationalité doit etre renseigné"),
        countryResidence: Yup.string().required("Le pays de résidence doit etre renseigné"),
        addressResidence: Yup.string().required("L'adresse de résidence doit etre renseigné"),
        numberPassport: Yup.string().required("Le numéro de passport doit etre renseigné"),
        expirationPassport: Yup.date()
            .transform(function (value, originalValue) {
                if (this.isType(value)) return value;
                return parse(originalValue, "dd/MM/yyyy", new Date());
            }).typeError("Une date valide")
            .min("2024-05-16", "Selectionnée une date valide")
            .required(),
        acquainted: Yup.string(),
        other: Yup.string(),
        password: Yup.string()
            .min(8, 'Le mot de passe doit contenir au minimum 8 caractère.')
            .required('mot de passe requis'),
        confirmPassword: Yup.string()
            .min(8, 'Confirmation mot de passe doit contenir au minimum 8 caractère.')
            .oneOf([Yup.ref('password'), null], 'Les mot de passe doivent correspondre')
            .required('Confirmation mot de passe requis'),
        condition: Yup.bool()
            .oneOf([true], "Les conditions général d'utilisation doivent etre accépté.")
            .required()
    });
};
