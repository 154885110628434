import * as Yup from "yup";
import { parse } from 'date-fns';

export const contractValidationSchema = () => {
    return Yup.object({
        lastname: Yup.string()
            .matches(/[a-zA-Z]/, 'Votre nom ne peut contenir que des caractère alphabétique.')
            .required('Vous devez renseigner votre nom.'),
        firstname: Yup.string()
            .matches(/[a-zA-Z]/, 'Votre prenom ne peut contenir que des caractère alphabétique.')
            .required('Vous devez renseigner votre prenom.'),
        nationality: Yup.string().required("Le pays de la nationalité doit etre renseigné"),
        countryReside: Yup.string().required("Le pays de résidence doit etre renseigné"),
        born: Yup.date()
            .transform(function (value, originalValue) {
                if (this.isType(value)) return value;
                return parse(originalValue, "dd/MM/yyyy", new Date());
            })
            .typeError("Une date valide")
            .max("2005-12-31", "Vous devez etre majeur")
            .required(),
        numberPass: Yup.string().required("Le numéro de passport doit etre renseigné"),
        expirPass: Yup.date()
            .transform(function (value, originalValue) {
                if (this.isType(value)) return value;
                return parse(originalValue, "dd/MM/yyyy", new Date());
            }).typeError("Une date valide")
            .min("2024-05-16", "Selectionnée une date valide")
            .required(),
        objectifTrip: Yup.string().required("La raison de votre voyage doit etre renseigné"),
        email: Yup.string().required('Email requis').email('Votre adresse email est invalide'),
        profession: Yup.string().required("La profession doit etre renseigné"),
        addressReside: Yup.string().required("L'adresse de résidence doit etre renseigné"),
        dateStart: Yup.date()
            .transform(function (value, originalValue) {
                if (this.isType(value)) return value;
                return parse(originalValue, "dd/MM/yyyy", new Date());
            }).typeError("Selectionnée une date valide")
            .required(),
        dateEnd: Yup.date()
            .when("dateStart",
                (dateStart, schema) => dateStart && schema.min(dateStart))
            .required()
    });
}
