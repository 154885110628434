import * as Yup from "yup";
import { parse } from "date-fns";

export const optionsCouverture = [
  { name: "Basique", value: 0, selected: true },
  { name: "Responsabilité civile", value: 1, selected: false },
  { name: "Refus visa", value: 2, selected: false },
  { name: "Rachat franchise", value: 3, selected: false },
  { name: "Toutes options", value: 4, selected: false },
];

export const optionsNbPersonne = [
  { name: "1", value: 1, selected: true },
  { name: "2", value: 2, selected: false },
  { name: "3", value: 3, selected: false },
  { name: "4", value: 4, selected: false },
  { name: "5", value: 5, selected: false },
  { name: "6", value: 6, selected: false },
  { name: "7", value: 7, selected: false },
  { name: "8", value: 8, selected: false },
  { name: "9", value: 9, selected: false },
  { name: "10", value: 10, selected: false },
  { name: "11", value: 11, selected: false },
  { name: "12", value: 12, selected: false },
  { name: "13", value: 13, selected: false },
  { name: "14", value: 14, selected: false },
  { name: "15", value: 15, selected: false },
  { name: "16", value: 16, selected: false },
  { name: "17", value: 17, selected: false },
  { name: "18", value: 18, selected: false },
  { name: "19", value: 19, selected: false },
  { name: "20", value: 20, selected: false },
  { name: "21", value: 21, selected: false },
  { name: "22", value: 22, selected: false },
  { name: "23", value: 23, selected: false },
  { name: "24", value: 24, selected: false },
  { name: "25", value: 25, selected: false },
  { name: "26", value: 26, selected: false },
  { name: "27", value: 27, selected: false },
  { name: "28", value: 28, selected: false },
  { name: "29", value: 29, selected: false },
  { name: "30", value: 30, selected: false },
  { name: "31", value: 31, selected: false },
  { name: "32", value: 32, selected: false },
];

export const currentDate = new Date().toLocaleString("default", {
  day: "2-digit",
  month: "2-digit",
  year: "2-digit",
});

export const AppValidationSchema = () => {
  return Yup.object({
    startDate: Yup.date()
      .transform(function (value, originalValue) {
        if (this.isType(value)) return value;
        return parse(originalValue, "dd/MM/yyyy", new Date());
      })
      .typeError("Une date valide")
      // .min("1969-11-13", "Date trop proche"),
      .required(),
    endDate: Yup.date()
      .transform(function (value, originalValue) {
        if (this.isType(value)) return value;
        return parse(originalValue, "dd/MM/yyyy", new Date());
      })
      .typeError("Une date valide")
      // .min("1969-11-13", "Date trop proche"),
      .required(),
  });
};
