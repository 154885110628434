import {Link, useNavigate } from "react-router-dom";
import commandService from "../../../../_services/command.service";
import { Loader } from '../../../../components/Loader';
import { format } from "date-fns";
import { useState, useEffect } from "react";

const ContractListContent = (props) => {
    const { id } = props;
    const [commands,setCommand] = useState([])
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        commandService.getCommands(id).then(r =>{
            console.log(r.data);
            setCommand(r.data);
            setLoading(false);
        }).catch(err => {
            console.log(err)
        });
    }, [id]);

    const redirectToShowContract = (command) => {
        console.log(command)
        if(command.status === "En attente"){
            console.log(command.status)
            navigate("/dashboard/commande/recapitulatif/" + command.id);
        }

        if(command.status === "Valide"){
            console.log(command.status)
            navigate("/dashboard/commande/affichage/" + command.id);
        }
    }

    return (
        <div className='px-[25px] pt-[25px] bg-[#F8F9FC] pb-[40px]'>
            <div className='flex items-center justify-between'>
                <h1 className='text-[28px] leading-[34px] font-normal text-[#5a5c69] cursor-pointer'>Dashboard</h1>
                <Link to="./create" className='bg-[#2E59D9] h-[32px] rounded-[3px] text-white flex items-center justify-center px-[8px]'>
                    Souscrire à un contrat
                </Link>
            </div>
            <div className='flex mt-[22px] w-full gap-[30px]'>
                <div className='basis-[100%] border bg-white shadow-md rounded-[4px]'>
                    <div className='bg-[#F8F9FC] flex items-center justify-between py-[15px] px-[20px] border-b-[1px] border-[#EDEDED]'>
                        <h2 className='text-[#4e73df] text-[16px] leading-[19px] font-bold'>Mes demandes de contrats</h2>
                    </div>
                    {loading ? <div className="p-12 w-full flex justify-around items-center"><Loader /></div> :
                        <div className="overflow-hidden rounded-lg shadow-xs">
                            <div className="overflow-x-auto">
                                <table className="w-full whitespace-no-wrap">
                                    <thead>
                                        <tr className="text-xs font-semibold tracking-wide text-left text-[#4e73df] uppercase border-b bg-white">
                                            <th className="px-4 py-3">Type de command</th>
                                            <th className="px-4 py-3">Nombre de contrat</th>
                                            <th className="px-4 py-3">Prix</th>
                                            <th className="px-4 py-3">Methode de paiement</th>
                                            <th className="px-4 py-3">Status</th>
                                            <th className="px-4 py-3">Date de création</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y">
                                    { Array.isArray(commands) && !commands.length ? <>                                         
                                        <tr className="text-s font-semibold text-center  border-b bg-white">
                                            <th colspan="6" className="px-4 py-3">Vous n'avez pas souscris de contrat.</th>
                                        </tr> </>: 
                                        <>{commands.map(command => (
                                            <tr key={command.id} className="text-gray-700 hover:bg-stone-200 cursor-pointer" onClick={() => redirectToShowContract(command)}>
                                                <td className="px-4 py-3">
                                                    <div className="flex items-center text-sm">
                                                        <p className="font-semibold">{command.typeCommand}</p>
                                                    </div>
                                                </td>
                                                <td className="px-4 py-3 text-sm">{command.numberContract}</td>
                                                <td className="px-4 py-3 text-sm">{command.amount}€</td>
                                                <td className="px-6 py-3 text-md">{command.methodPaiement}</td>
                                                <td className="px-4 py-3 text-xs">
                                                    <span className={`font-semibold leading-tight ${command.status === 'Valide' ? 'text-green-700 bg-green-100 px-5 py-1' : 'text-orange-700 bg-orange-100 px-2 py-1'}  rounded-full`}>
                                                        {command.status}
                                                    </span>
                                                </td>
                                                <td className="px-4 py-3 text-sm">{format(new Date(command.createdAt), "dd/MM/yyyy")}</td>
                                            </tr>
                                        ))}</>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default ContractListContent;