export const renderAvantage = [
  {
    id: 1,
    message: "A partir de 0.63€ / jour (*)",
  },
  {
    id: 2,
    message: "Aucune limite d'age pour s'assurer",
  },
  {
    id: 3,
    message:
      "Offre de remboursement des frais de visa en cas de refus par l'ambassade ou le consulat",
  },
  {
    id: 4,
    message:
      "Durée de garantie pouvant aller de 3 jours jusqu'a 12 mois renouvelables",
  },
  {
    id: 5,
    message:
      "Extension de garantie a des pays n'appartenant pas a la zone Schengen",
  },
  {
    id: 6,
    message:
      "Vous pouvez souscrire et gérer les dossiers pour le compte de tier (famille, relations ou groupe)",
  },
  {
    id: 7,
    message: "Vous recevez directement votre certificat par email",
  },
  {
    id: 8,
    message:
      "Votre espace client vous permet d'imprimer votre certificat dans la langue désirée, de modifier vos informations (créer un avenant) et de déclarer et suivre un sinistre.",
  },
];

export const renderPrecision = [
  {
    id: 1,
    message: "A qui s'adresse le contrat d'assurance voyage Schengen ?",
  },
  {
    id: 2,
    message: "Dans quels pays suis-je couvert avec le contrat d'assurance Voyage Schengen ?",
  },
  {
    id: 3,
    message:
      "Le contrat m'assure t'il dans plusieurs pays d'Europe ?",
  },
  {
    id: 4,
    message:
      "Jusau'a quel page puis-je souscrire une assurance voyage Schengen ?",
  },
];
