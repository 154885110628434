import React from "react";
import { Footer } from "../../../components/Footer";
import { Navbar } from "../../../components/Navbar";
import {Banner} from "../../../components/Banner/banner";
import {LoginForm} from "./FormLogin/LoginForm";
import {DocumentList} from "../../Public/DevisPage/DocumentList";

const LoginPage = () => {
    return (
        <>
            <Navbar />
            <Banner />
            <LoginForm/>
            <DocumentList />
            <Footer />
        </>
    );
};

export default LoginPage;