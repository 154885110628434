import React from "react";
import { Card } from "./Card";

export const FiveBanner = () => {
  const formuleData = [
    {
      title: "Basique",
      from: "L'assurance Schengen,",
      price: "0.63€*/jour",
      gamme: "basics",
    },
    {
      title: "Options Indispensables",
      from: "L'assurance Espace Schengen,",
      price: "0.74€*/jour",
      gamme: "middle",
    },
    {
      title: "Toutes Options",
      from: "Une formule d'assurance Schengen premium,",
      price: `0.93€*/jour`,
      gamme: "full",
    },
  ];


  return (
    <div className="w-full text-blue-primary py-[30px] px-12">
      <h3 className="text-2xl mb-3 font-bold flex justify-center">
        Des formules d'assurances Schengen simples et attractives
      </h3>
      <div className="flex items-center justify-center pt-3 ">
        <Card data={formuleData[0]} />
        <Card data={formuleData[1]} />
        <Card data={formuleData[2]} />
      </div>
      <span className="text-xs">
        &#42;exemple de tarif journalier pour une assurance Schengen de 90 jours
      </span>
    </div>
  );
};
