import { Footer } from "../../../components/Footer";
import { Navbar } from "../../../components/Navbar";
import {Banner} from "../../../components/Banner/banner";
import {Intro} from "./Intro/Intro";
import { GarantiContent } from "./Content/GarantiContent";
import { RedirectBanner } from "./RedirectBanner/RedirectBanner"
import {DocumentList} from "../DevisPage/DocumentList";

const GarantiPage = () => {
    return (
        <>
            <Navbar />
            <Banner />
            <Intro />
            <GarantiContent />
            <RedirectBanner />
            <DocumentList />
            <Footer />
        </>
    );
};

export default GarantiPage;