import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import { Loader } from "../../../../components/Loader";
import { format } from "date-fns";
import { ModalForm } from "../../../../components/ModalForm";
import { Modal } from "../../../../components/Modal";
import contractService from "../../../../_services/contract.service";


const ContractModifyContent = () => {
    const [contract, setContract] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessageOpen, setModalMessageOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const contractId = useParams().id;

    useEffect(() => {
        contractService.getContract(contractId).then(r =>{
            console.log(r.data);
            setContract(r.data);
            setLoading(false);
        }).catch(err => {
            console.log(err)
        });
    }, [contractId]);

    const handleButtonClick = () => {
        setModalOpen(false);
    };

    // const paymentRedirection = () => {
    //     commandService.paimentCommand(commandId).then(r => {
    //         if (r.status === 200){
    //             if(r.data.result.shortMessage === "ERROR"){
    //                 console.log("error api payline");
    //             }else {
    //                 window.location.href = r.data.redirectURL;
    //             }
    //         }
    //     }).catch(err => {
    //         console.log(err)
    //     })
    // }

    // const handleButtonClickCanceledModifyContract = () => {
    //     commandService.deleteCommand(commandId).then(r => {
    //         console.log(r);
    //         if (r.status === 200){
    //             if(r.data){
    //                 navigate("/dashboard/commande/");
    //             }else {
    //                 console.log("Une erreur s'est produite lors de la suppression de la commande")
    //             }
    //         }
    //     }).catch(err => {
    //         console.log(err)
    //     })
    // }

    const handleButtonClickCanceled = () => {
        setModalMessageOpen(false);
    }

    const cancelRedirection = () => {
        setMessage("Voulez vous vraiment annuler la modification du contrat en cours ?")
        setModalMessageOpen(true);
    }

    return (
        <div className='px-[25px] pt-[25px] bg-[#F8F9FC] pb-[40px]'>
            <div className='flex items-center justify-between'>
                <h1 className='text-[28px] leading-[34px] font-normal text-[#5a5c69] cursor-pointer'>Dashboard</h1>
            </div>
            <div className='flex mt-[22px] w-full gap-[30px]'>
                <div className='basis-[100%] border bg-white shadow-md cursor-pointer rounded-[4px]'>
                    <div className='bg-[#F8F9FC] flex items-center justify-between py-[15px] px-[20px] border-b-[1px] border-[#EDEDED] mb-[20px]'>
                        <h1 className='text-[#4e73df] text-[16px] leading-[19px] font-bold'>Modification de la commande</h1>
                    </div>
                    {loading ? <div className="p-12 w-full flex justify-around items-center"><Loader /></div> : <>
                            <div key={contract.id} className={`bg-white w-full}`}>
                                <form className="px-24 py-12  flex justify-around">
                                    <div className="border-b border-gray-900/10 pb-15 border shadow rounded-lg p-5 w-4/12 ">
                                        <h2 className='text-[#4e73df] text-[16px] leading-[19px] font-bold'>Informations Personnelles</h2>
                                        <div className="sm:col-start-1 col-end-1 mt-2">
                                            <label className="font-medium">Genre: </label>
                                            {(contract.gender === "male") ? "Monsieur" : "Madame"}
                                        </div>
                                        <div className="sm:col-span-2 mt-2">
                                            <label className="font-medium">Nom: </label>
                                            {contract.firstname}
                                        </div>
                                        <div className="sm:col-span-3 mt-2">
                                            <label className="font-medium">Prénom: </label>
                                            {contract.lastname}
                                        </div>
                                        <div className="sm:col-span-3 mt-2">
                                            <label className="font-medium">Pays de nationalité: </label>
                                            {contract.nationality}
                                        </div>
                                        <div className="sm:col-span-3 mt-2">
                                            <label className="font-medium">Pays de residence: </label>
                                            {contract.countryReside}
                                        </div>
                                        <div className="sm:col-span-3 mt-2">
                                            <label className="font-medium">Date de naissance: </label>
                                            {format(new Date(contract.born), "dd/MM/yyyy")}
                                        </div>
                                        <div className="sm:col-span-3 mt-2">
                                            <label className="font-medium">langue parlée: </label>
                                            {contract.speaking}
                                        </div>
                                    </div>
                                    <div className="border-b border-gray-900/10 pb-12 border shadow rounded-lg p-5 w-4/12">
                                        <h2 className='text-[#4e73df] text-[16px] font-bold'>Informations additionnelles</h2>
                                        <div className="sm:col-span-3 mt-2">
                                            <label className="font-medium">N° passport: </label>
                                            {contract.numberPass}
                                        </div>
                                        <div className="sm:col-span-3 mt-2">
                                            <label className="font-medium">Date d'éxpir. passport: </label>
                                            {format(new Date(contract.expirPass), "dd/MM/yyyy")}
                                        </div>
                                        <div className="sm:col-span-3 mt-2">
                                            <label className="font-medium">Objet de votre visite: </label>
                                            {contract.objectifTrip}
                                        </div>
                                        <div className="sm:col-span-3 mt-2">
                                            <label className="font-medium">Email: </label>
                                            {contract.email}
                                        </div>
                                        <div className="sm:col-span-3 mt-2">
                                            <label className="font-medium">Profession: </label>
                                            {contract.profession}
                                        </div>
                                        <div className="sm:col-span-3 mt-2">
                                            <label className="font-medium">Adresse de residence: </label>
                                            {contract.addressReside}
                                        </div>
                                    </div>
                                    <div className="border-b border-gray-900/10 pb-12 border shadow rounded-lg p-5 w-4/12">
                                        <h2 className='text-[#4e73df] text-[16px] leading-[19px] font-bold'>Vos choix</h2>
                                        <div className="sm:col-span-3 mt-2">
                                            <label htmlFor="dateStart" className="font-medium leading-3">Date de départ: </label>
                                            {format(new Date(contract.dateStart), "dd/MM/yyyy")}
                                        </div>
                                        <div className="sm:col-span-3 mt-2">
                                            <label htmlFor="born" className="font-medium">Date de retour: </label>
                                            {format(new Date(contract.dateEnd), "dd/MM/yyyy")}
                                        </div>
                                        <div className="sm:col-start-1 col-end-4 mt-2">
                                            <label className="font-medium">langue certifié: </label>
                                            {contract.speakCertif}
                                        </div>
                                        <div className="sm:col-start-4 col-end-8 mt-2">
                                            <label className="font-medium">Destination: </label>
                                            {contract.destination}
                                        </div>
                                        <div className="sm:col-span-3 mt-2">
                                            <label className="font-medium">+15 jour: </label>
                                            {(contract.moreFifteenDays !== null && contract.moreFifteenDays !== false) ? "Souscris " : 'Non souscris'}
                                        </div>
                                        <div className="sm:col-span-3 mt-2">
                                            <label className="font-medium">Refus visa: </label>
                                            {(contract.repaymentVisa !== null && contract.repaymentVisa !== false) ? "Souscris " : 'Non souscris'}
                                        </div>
                                        <div className="sm:col-span-3 mt-2">
                                            <label className="font-medium">Resp. civile: </label>
                                            {(contract.responsabilityCivil !== null && contract.responsabilityCivil !== false) ? "Souscris " : 'Non souscris'}
                                        </div>
                                        <div className="sm:col-span-3 mt-2">
                                            <label className="font-medium">Rachat franchise: </label>
                                            {(contract.noFranchise !== null && contract.noFranchise !== false) ? "Souscris " : 'Non souscris'}
                                        </div>
                                        <div className="sm:col-span-3 mt-2">
                                            <label className="font-medium">MultiTrip: </label>
                                            {(contract.multiTrip !== null && contract.multiTrip !== false) ? "Souscris " : 'Non souscris'}
                                        </div>
                                    </div>
                                </form>
                                {modalOpen &&
                                        <ModalForm
                                            closeModal={handleButtonClick}
                                            onCancel={handleButtonClick}
                                            contract={contract}
                                            dateStart={format(new Date(contract.dateStart), "yyyy-MM-dd")}
                                            dateEnd={format(new Date(contract.dateEnd), "yyyy-MM-dd")}
                                        />
                                }
                                <div className="flex justify-center">
                                    <button
                                        className="focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center bg-blue-secondary text-yellow-primary "
                                        type="button"
                                        onClick={() => setModalOpen(true)}>
                                        Modifier le contrat
                                    </button>
                                </div>
                                <hr  className="mt-10"/>
                            </div>
                    </>}
                </div>
            </div>
            <div className="flex justify-center mt-10">
                <h2 className="text-[16px] leading-[19px] font-bold">Activer un code promo: </h2>
                <input type="text" />
            </div>
            <p>Détails des prix</p>
            {loading ? <div className="p-12 w-full flex justify-around items-center"><Loader /></div> : <>
                <div>
                    <p>Contrat pour {contract.lastname} {contract.firstname}</p>
                    <p>Prix de base : {contract.price}</p>
                    <p>{(contract.repaymentVisa !== null && contract.repaymentVisa !== false) ? "Option refus de visa : " + contract.repaymentVisa : ''}</p>
                    <p>{(contract.responsabilityCivil !== null && contract.responsabilityCivil !== false) ? "Option responsabilité civile : " + contract.responsabilityCivil : ''}</p>
                    <p>{(contract.noFranchise !== null && contract.noFranchise !== false) ? "Option rachat franchise : : " + contract.noFranchise : ''}</p>
                    <p>{(contract.multiTrip !== null && contract.multiTrip !== false) ? "Option multiTrip : " + contract.multiTrip : ''}</p>
                    <hr  className="mt-10"/>
                </div>
            </>}
            <p>Prix de la command: {contract.price}</p>
            { modalMessageOpen &&
                    <Modal
                        twoBtn={true}
                        closeModalCanceled={handleButtonClickCanceled}
                        closeModal={handleButtonClickCanceled}
                        /*closeModalValid={handleButtonClickCanceledCommand}*/
                        message={message}
                    />
                }
            <div className="flex justify-center mt-10">
                <button 
                    className="focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center bg-red-600 text-white"
                    onClick={cancelRedirection}
                    type="button">
                        Annuler la modification
                </button>
                <button 
                    className="focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center bg-blue-secondary text-yellow-primary "
                    /*onClick={paymentRedirection}*/
                    type="button">
                        Valider la modification
                </button>
            </div>
        </div>
    );
}

export default ContractModifyContent;