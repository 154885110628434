import React from "react";
export const Intro = () => {
    return (
        <div className="text-center m-10">
                <h3 className="text-blue-primary text-2xl mb-3 font-bold">Garanties de base</h3>
                <p>assistance frais medicaux hospitalisation et rapatriement</p>
                <h1 className="m-5 text-blue-primary text-2xl mb-3 font-bold">Extensions acquises sans formalité</h1>
                <p>Aucune limitation d'age a la souscription.</p>
                <p>Periode de garantie allant de 3 jours à 1 ans renouvelable.</p>
                <p>Certains pays qui ne font pas partie de l'espace Schengen (Voir tableau ci dessous).</p>
                <h1 className="m-5 text-blue-primary text-2xl mb-3 font-bold">Garanties optionnelles</h1>
                <p>Responsabilité civile familiale (option réservée aux contrats dont la durée n'excède pas 4 mois).</p>
                <p>Remboursement des frais de demande de visa en cas de refus de l'ambassade ou du consulat.</p>
                <p>Durée de validité étendue de 15 jours selon le réglement (CE) n°810/2009 du parlement européen.</p>
                <p className="m-10">Toutes ces garanties répondent parfaitement à l'ensemble des exigences imposées par les accords Schengen.</p>
        </div>
    );
};
