import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export const ScButton = ({ className, children, onClick, type }) => {
  const btnClass = classNames(
    className,
    "w-full focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center",
    {
      "bg-blue-secondary text-yellow-primary":
        type === "primary",
      // "bg-yellow-600 hover:bg-yellow-700 text-blue-700 hover:text-blue-800":
      //   type === "secondary",
    }
  );

  return (
    <button
      type="button"
      className={btnClass}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

ScButton.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

ScButton.defaultProps = {
  onClick: () => {},
  type: "primary",
};
