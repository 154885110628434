import React from "react";

export const Arrow = () => (

    <svg fill="#000000" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg" >
        <g id="SVGRepo_bgCarrier" stroke-width="0">
        </g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
            <path d="M224 0H106.667V1339.83H1700.04L1223.57 1835.48L1304.82 1920L1920 1280.06L1304.82 640L1223.57 724.52L1700.04 1220.29H224V0Z"></path>
        </g>
    </svg>

);
