export const renderStandard = [
  {
    id: 1,
    message: "Durée du séjour : Nombre de voyage dans l'année illimité dans la limite de 90 jours maximum par voyage.",
  },
  {
    id: 2,
    message: "Pays ouverts : Zone Schengen, DOM, COM Andorre, Monaco, Saint Marin, Vatican, Irlande, Royaume Uni.",
  },
  {
    id: 3,
    message: "Fais médicaux, hospitalisation : 35 000€",
  },
  {
    id: 4,
    message: "Assistance - Rapatriement : Frais réels",
  },
  {
    id: 5,
    message: "Frais de recherche ou de secours : 762€",
  },
  {
    id: 6,
    message: "Prolongation du séjour : Oui",
  },
  {
    id: 7,
    message: "Rapatriement funéraire : 3 500€",
  },
  {
    id: 8,
    message: "Envoi de médicament en Europe : Frais réels",
  },
  {
    id: 9,
    message: "Assistance juridique : 6000€",
  },
  {
    id: 10,
    message: "Franchise sur dossier médicaux : 80€ (Sauf en cas d'accident)",
  },
  {
    id: 11,
    message: "Franchise sur responsabilité civile privée : 80€",
  },
  {
    id: 12,
    message: "Remboursement prime assurance en cas de refus de visa : Non",
  },
  {
    id: 13,
    message: "Participation aux frais de demande de visa en cas de refus : Dans la limite de 50 € (optionnel)",
  },
  {
    id: 14,
    message: "Responsabilité civile vie privée (dommages corporel et immatériels consécutifs) : 1 500 000€ (optionnel)",
  },
];

export const renderMultiTrip = [
  {
    id: 1,
    message: "Durée du séjour : Nombre de voyage dans l'année illimité dans la limite de 90 jours maximum par voyage.",
  },
  {
    id: 2,
    message: "Pays ouverts : Zone Schengen, DOM, COM Andorre, Monaco, Saint Marin, Vatican, Irlande, Royaume Uni.",
  },
  {
    id: 3,
    message: "Fais médicaux, hospitalisation : 35 000€",
  },
  {
    id: 4,
    message: "Assistance - Rapatriement : Frais réels",
  },
  {
    id: 5,
    message: "Frais de recherche ou de secours : 762€",
  },
  {
    id: 6,
    message: "Prolongation du séjour : Oui",
  },
  {
    id: 7,
    message: "Rapatriement funéraire : 3 500€",
  },
  {
    id: 8,
    message: "Envoi de médicament en Europe : Frais réels",
  },
  {
    id: 9,
    message: "Assistance juridique : 6000€",
  },
  {
    id: 10,
    message: "Franchise sur dossier médicaux : 80€ (Sauf en cas d'accident)",
  },
  {
    id: 11,
    message: "Franchise sur responsabilité civile privée : 80€",
  },
  {
    id: 12,
    message: "Remboursement prime assurance en cas de refus de visa : Non",
  },
  {
    id: 13,
    message: "Participation aux frais de demande de visa en cas de refus : Dans la limite de 50 € (optionnel)",
  },
  {
    id: 14,
    message: "Responsabilité civile vie privée (dommages corporel et immatériels consécutifs) : 1 500 000€ (optionnel)",
  },
];
