import { BrowserRouter, Routes, Route } from 'react-router-dom';
import DashboardRouter from './pages/Dashboard/DashboardRouter';
import PublicRouter from './pages/Public/PublicRouter';
import AuthRouter from './pages/Auth/AuthRouter';
import AuthGuard from './_helpers/authProvider';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<PublicRouter />}/>
          <Route path="/auth/*" element={<AuthRouter/>}/>
          <Route path="/dashboard/*" element={
            <AuthGuard>
              <DashboardRouter />
            </AuthGuard>
          }/>
        </Routes>
      </BrowserRouter>
    </ >
  );
}

export default App;