import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import commandService from "../../../../_services/command.service";
import { Loader } from "../../../../components/Loader";
import TemplatePDFLink from "../../../../components/TemplatePDF/TemplatePDFLink";


const ContractDetailContent = () => {
    const commandId = useParams().id;
    const [loading, setLoading] = useState(true);
    const [contracts, setContract] = useState([]);
    
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchContract() {
            const contractData = await commandService.getCommandById(commandId);
            setContract(contractData);
            setLoading(false);
        }
    
        fetchContract();
    }, [commandId, navigate]);

    return (
        <div>
            <div className='px-[25px] pt-[25px] bg-[#F8F9FC] pb-[40px]'>
                <div className='flex items-center justify-between'>
                    <h1 className='text-[28px] leading-[34px] font-normal text-[#5a5c69] cursor-pointer'>Dashboard</h1>
                </div>
            </div>
            { loading ? <div className="p-12 w-full flex justify-around items-center"><Loader /></div>  : 
                <>
                    <div className='bg-[#F8F9FC] flex items-center justify-between py-[15px] px-[20px] border-b-[1px] border-[#EDEDED] mb-[20px]'>
                        <h2 className='text-[#4e73df] text-[16px] leading-[19px] font-bold'>Certificat</h2>
                    </div>
                    {contracts.map((contract) => (
                        <div key={contract.id}>
                            <p>Certificat de {contract.lastname} {contract.firstname}</p>
                            <table className="border-collapse">
                                <tbody>
                                    <tr>
                                        <td className="border p-5"><NavLink to={`/dashboard/commande/affichage/certificat/${contract.id}`} className="underline hover:decoration-dashed">Visualiser le certificat</NavLink></td>
                                        <td className="border p-5"><NavLink to={`/dashboard/commande/modification/${contract.id}`} className="underline hover:decoration-dashed">Modifier le contract</NavLink></td>
                                        <td className="border p-5"><TemplatePDFLink key={contract.id} contract={contract}/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    ))}
                </>
            }
        </div>
    );
}

export default ContractDetailContent;