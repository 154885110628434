import {PDFViewer} from "@react-pdf/renderer";
import TemplatePDF from "./TemplatePDF";

const TemplatePDFView = (props) =>  {
    const { contract } = props;
    return (
        <>
            <PDFViewer width={"100%"} height={"750px"}>
                <TemplatePDF contract={contract} />
            </PDFViewer>
        </>
    )
}

export default TemplatePDFView;