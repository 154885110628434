import React from 'react'
import {ScButton} from "../../../../components/ScButton";
import _ from "lodash";
import {useNavigate} from "react-router-dom";
import accountService from '../../../../_services/account.service';

export const Account = ({ formik }) => {
  const { handleChange, values } = formik;
  const navigate = useNavigate();

  let submitFormConnection = () => {
    const auth = {
      email: values.email,
      password: values.password
    }
    accountService.login(auth).then((r) => {
      console.log(r, " <= response");
      accountService.saveToken(r.data.apiToken);
      navigate("/dashboard");
    }).catch((e) => {
      console.log(e, " <= error ")
      if (e.response.data.errors !== undefined) {
        console.log(e);
        // setValidationErrors(e.response.data.errors);
      }
      if (e.response.data.error !== undefined) {
        // setValidationErrors(e.response.data.error);
      }
    });
  }

  let submitFormInscription = () => {
    console.log(values);
    navigate("/auth/register");
  }

  return (
      <div className="p-24">
        <h1 className="pb-8 text-xl text-left font-bold">Votre compte client</h1>
        <div className="flex justify-around">
          <div className="border w-[700px] shadow rounded-lg p-10">
            <div className="mt-5">
              <p className="text-lg font-bold">Vous avez deja un compte ?</p>
              <p className="text-lg font-bold">Veuillez saisir vos identifiants pour finaliser votre souscription :</p>
              <div className="sm:col-span-3 mt-10">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Adresse email
                </label>
                <div className="mt-2">
                  <input
                      type="text"
                      name="email"
                      id="email"
                      onChange={handleChange}
                      autoComplete="family-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Mot de passe
                </label>
                <div className="mt-2">
                  <input
                      type="password"
                      name="password"
                      id="password"
                      onChange={handleChange}
                      autoComplete="family-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="flex justify-center mt-10">
                <ScButton
                    type="primary"
                    children={_.upperCase("Se connecter")}
                    className="font-bold w-[150px]"
                    onClick={submitFormConnection}
                />
              </div>
            </div>
          </div>
          <div className="border w-[700px] shadow rounded-lg p-10">
            <form className="mt-5">
              <p className="text-lg font-bold">Je crée mon compte</p>
              <p className="text-lg font-bold">Pour souscrire une assurance, vous devez créer votre compte</p>
              <fieldset>
                <div className="flex items-center mt-5">
                  <label htmlFor="solo" className={`block mb-1 text-sm font-medium text-gray-900`}>
                    <input
                        id="solo"
                        value="solo"
                        type="radio"
                        onChange={handleChange}
                        name="assure"
                        checked
                    />
                    &nbsp;&nbsp;Je suis la personne à assurer
                  </label>
                </div>
                <div className="flex items-center mt-5">
                  <label htmlFor="party" className={`block mb-1 text-sm font-medium text-gray-900`}>
                    <input
                        id="party"
                        value="party"
                        type="radio"
                        onChange={handleChange}
                        name="assure"
                    />
                    &nbsp;&nbsp;Je fais parti d'un groupe de personne à assurer
                  </label>
                </div>
                <div className="flex items-center mt-5">
                  <label htmlFor="other" className={`block mb-1 text-sm font-medium text-gray-900`}>
                    <input
                        id="other"
                        value="other"
                        type="radio"
                        onChange={handleChange}
                        name="assure"
                    />
                    &nbsp;&nbsp;Je ne fais pas parti des personne à assurer
                  </label>
                </div>
              </fieldset>
              <div className="flex justify-center">
                <ScButton
                    type="primary"
                    children={_.upperCase("Continuer")}
                    className="font-bold w-[150px] mt-20"
                    onClick={submitFormInscription}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
  );
}
