import React from 'react'
const Dashboardview = (props) => {
    const {name} = props;
    return (
        <div>
            <div className='flex items-center justify-between h-[70px] shadow-lg px-[25px] '>
                <div className='flex items-center rounded-[5px]'>
                </div>
                <div className='flex items-left gap-[20px]'>
                    <div className='flex items-center gap-[15px] relative'>
                        <p>Bienvenue {name}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboardview
