import React, { useState }from "react";
import { Select } from "../Select";
import contractService from "../../_services/contract.service";
import { ScInput } from "../ScInput";
import { format } from "date-fns";
import { optionsListCountry } from "../../pages/Public/DevisPage/Form/_const";
import {contractValidationSchema} from "../../pages/Dashboard/Content/Contract/_const";

import "./index.scss";
import userService from "../../_services/user.service";

export const ModalFormUser = ({ closeModal, onCancel, user}) => {
    const [ errors, setErrors] = useState({});
    const [ userUpdated, setUserUpdated] = useState({
        id: user.id,
        updatedAt: new Date(),
        gender: user.gender,
        firstname: user.firstname,
        lastname: user.lastname,
        nationality: user.nationality,
        countryResidence: user.countryResidence,
        phoneNumber: user.phoneNumber,
        born: format(new Date(user.born), "yyyy-MM-dd"),
        numberPassport: user.numberPassport,
        expirPassport: format(new Date(user.expirPassport), "yyyy-MM-dd"),
        occupation: user.occupation,
        addressResidence: user.addressResidence,
    });

    const onChangeForm = (e) => {
        setUserUpdated(user => ({
            ...user,
            [e.target.name]: e.target.value,
        }));
    }

    const checkForm = async () => {
        try {
            await contractValidationSchema().validate(userUpdated, { abortEarly: false });
            setErrors({})
            return true;
        } catch (error) {
            const validationErrors = {};
            error.inner.forEach((err) => {
              if (err.path !== undefined) {
                validationErrors[err.path] = err.errors[0];
              }
            });
            setErrors(validationErrors);
            return false;
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        // const formValid = await checkForm();
        // if(formValid){
            userService.updateUserInfo(userUpdated).then(r => {
                console.log(r)
                closeModal();
                //window.location.reload();
            }).catch(err => {
                console.error(err)
            })
        // }
    }

    return (
        <div className="modal-container"
             onClick={(e) => {
                 if (e.target.className === "modal-container")
                     closeModal();
             }}>
            <form className="modal" method="post">
                <div className="modal-header">
                    <p className="text-[#4e73df] text-[16px] leading-[19px] font-bold">Modifiaction des informations personnels</p>
                    <p onClick={() => closeModal()} className="close">&times;</p>
                </div>
                <div className="modal-content">
                    <div className="px-12 py-6 flex justify-around">
                        <div className="grid gap-4 grid-cols-3 border-b border-gray-900/10 pb-15 border shadow rounded-lg p-5 w-full">
                            <div className="sm:col-span-1">
                                <label htmlFor="gender" className="block text-sm font-medium leading-6 text-gray-900">
                                    Genre
                                </label>
                                <select name="gender"
                                    defaultValue={userUpdated.gender}
                                    onChange={onChangeForm}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                    <option value="male">Monsieur</option>
                                    <option value="female">Madame</option>
                                </select>
                            </div>
                            <div className="sm:col-span-1">
                                <label htmlFor="lastname" className="block text-sm font-medium leading-6 text-gray-900">
                                    Nom
                                </label>
                                    <input
                                        type="text"
                                        name="lastname"
                                        id="lastname"
                                        autoComplete="given-name"
                                        defaultValue={userUpdated.lastname}
                                        onChange={onChangeForm}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                {errors.lastname ? (
                                    <div className="text-red-700">{errors.lastname}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-1">
                                <label htmlFor="firstname" className="block text-sm font-medium leading-6 text-gray-900">
                                    Prénom
                                </label>
                                    <input
                                        type="text"
                                        name="firstname"
                                        id="firstname"
                                        autoComplete="given-name"
                                        defaultValue={user.firstname}
                                        onChange={onChangeForm}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                {errors.firstname ? (
                                    <div className="text-red-700">{errors.firstname}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="occupation" className="block text-sm font-medium leading-6 text-gray-900">
                                    Profession :
                                </label>
                                <input
                                    type="text"
                                    name="occupation"
                                    id="occupation"
                                    autoComplete="given-name"
                                    defaultValue={userUpdated.occupation}
                                    onChange={onChangeForm}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                {errors.occupation ? (
                                    <div className="text-red-700">{errors.occupation}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-1">
                                <label htmlFor="phoneNumber" className="block text-sm font-medium leading-6 text-gray-900">
                                    Téléphone
                                </label>
                                <input
                                    type="text"
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    autoComplete="family-name"
                                    defaultValue={userUpdated.phoneNumber}
                                    onChange={onChangeForm}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                {errors.phoneNumber ? (
                                    <div className="text-red-700">{errors.phoneNumber}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-1">
                                <ScInput
                                    type="date"
                                    name="born"
                                    id="born"
                                    value={userUpdated.born}
                                    onChange={onChangeForm}
                                    label="Date de naissance :"
                                    position="right"
                                />
                                {errors.born ? (
                                    <div className="text-red-700">{errors.born}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-1">
                                <label htmlFor="nationality" className="block text-sm font-medium leading-6 text-gray-900">
                                    Nationalité :
                                </label>
                                <input
                                    type="text"
                                    name="nationality"
                                    id="nationality"
                                    autoComplete="given-name"
                                    defaultValue={user.nationality}
                                    onChange={onChangeForm}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                {errors.nationality ? (
                                    <div className="text-red-700">{errors.nationality}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-1">
                                <label htmlFor="countryResidence" className="block text-sm font-medium leading-6 text-gray-900">
                                    Pays de residence
                                </label>
                                <input
                                    type="text"
                                    name="countryResidence"
                                    id="countryResidence"
                                    autoComplete="family-name"
                                    defaultValue={userUpdated.countryResidence}
                                    onChange={onChangeForm}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                {errors.countryResidence ? (
                                    <div className="text-red-700">{errors.countryResidence}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="numberPassport" className="block text-sm font-medium leading-6 text-gray-900">
                                    N° passport :
                                </label>
                                <input
                                    type="text"
                                    name="numberPassport"
                                    id="numberPassport"
                                    autoComplete="family-name"
                                    defaultValue={userUpdated.numberPassport}
                                    onChange={onChangeForm}
                                    className="block  w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                {errors.numberPassport ? (
                                    <div className="text-red-700">{errors.numberPassport}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-1">
                                <ScInput
                                    type="date"
                                    name="expirPassport"
                                    id="expirPassport"
                                    value={userUpdated.expirPassport}
                                    onChange={onChangeForm}
                                    label="Date d'éxpir. passport :"
                                    position="right"
                                />
                                {errors.expirPassport ? (
                                    <div className="text-red-700">{errors.expirPassport}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="addressResidence" className="block text-sm font-medium leading-6 text-gray-900">
                                    Adresse de résidence (*) :
                                </label>
                                <input
                                    type="text"
                                    name="addressResidence"
                                    id="addressResidence"
                                    autoComplete="family-name"
                                    defaultValue={userUpdated.addressResidence}
                                    onChange={onChangeForm}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                {errors.addressResidence ? (
                                    <div className="text-red-700">{errors.addressResidence}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                <button
                        type="submit"
                        className="focus:outline-none font-medium rounded-full text-sm px-5 text-center bg-red-600 text-white"
                        onClick={() => onCancel()}
                    >
                        Annuler
                    </button>
                    <button
                        type="submit"
                        className="focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center bg-blue-secondary text-yellow-primary "
                        onClick={onSubmit}
                    >
                        Valider
                    </button>
                </div>
            </form>
        </div>
    );
}

export default ModalFormUser;