import Axios from './caller.service'

let getSinisterDeclared = (id) => {
    return Axios.get('/sinister/user/'+ id);
}

let getCreateSinister = (sinister) => {
    return Axios.post('/sinister/upload', sinister, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });
}

let getDeleteSinister = (id) => {
    return Axios.delete('/sinister/'+ id);
}

let getUpdatedSinister = (sinister) => {
    return Axios.put('/sinister/update');
}


const sinisterService = {
    getSinisterDeclared, getCreateSinister, getDeleteSinister, getUpdatedSinister
}

// Déclaration des services pour import
export default sinisterService;