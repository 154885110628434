import {Document, Image, Page, StyleSheet, View, Text} from "@react-pdf/renderer";
import MyImage from "../../assets/images/C_AVS_FR_page-0001.jpg";
import { format } from "date-fns";

const TemplatePDF = (props) =>  {
    const { contract } = props;
    
    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#E4E4E4'
        },
        img: {
            zIndex:0
        },
        text: {
            position: "absolute",
            left: '0px',
            right: '0px',
            marginHorizontal: 'auto',
            textAlign: "center",
            justifyContent: 'center',
            fontSize: 12,
        },
        textRight: {
            position: "absolute",
            left: '300px',
            right: '0px',
            marginHorizontal: 'auto',
            textAlign: "center",
            justifyContent: 'center',
            fontSize: 12,
        }
    });

    return (
        <Document style={styles.page}>
            <Page size="A4" style={styles.page}>
                <View>
                    <Image style={styles.img} src={MyImage}></Image>
                </View>    
                <Text style={{top:"232",...styles.text}} >
                   {contract.lastname}
                </Text>
                <Text style={{top:"249",...styles.text}} >
                    {contract.firstname}
                </Text>
                <Text style={{top:"265",...styles.text}} >
                    {format(new Date(contract.born), "dd/MM/yyyy")}
                </Text>
                <Text style={{top:"280",...styles.text}} >
                    {contract.nationality}
                </Text>
                <Text style={{top:"300",...styles.text}} >
                    {contract.numberPass}
                </Text>
                <Text style={{top:"320",...styles.text}} >
                    {contract.addressReside}
                </Text>
                <Text style={{top:"335",...styles.text}} >
                    {contract.countryReside}
                </Text>
                <Text style={{top:"352",...styles.text}} >
                    {contract.destination}
                </Text>
                <Text style={{top:"370",...styles.text}} >
                    {format(new Date(contract.dateStart), "dd/MM/yyyy")}
                </Text>
                <Text style={{top:"370", ...styles.textRight}} >
                    {format(new Date(contract.dateEnd), "dd/MM/yyyy")}
                </Text>
                <Text style={{top:"430", ...styles.textRight}} >
                    35.000 / {contract.noFranchise}
                </Text>
                <Text style={{top:"445", ...styles.textRight}} >
                    Frais réels
                </Text>
                <Text style={{top:"465", ...styles.textRight}} >
                    3.500
                </Text>
                <Text style={{top:"485", ...styles.textRight}} >
                    {contract.responsabilityCivil}
                </Text>
                <Text style={{top:"502", ...styles.textRight}} >
                    1.500.000 / {contract.repaymentVisa}
                </Text>
                <Text style={{top:"518", ...styles.textRight}} >
                    {contract.repaymentVisa}
                </Text>
            </Page>
        </Document>
    );
}

export default TemplatePDF;