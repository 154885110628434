import _ from "lodash";
import React from "react";
//import styles from "./style.module.scss";
import { actuContent } from "./_const";

export const EightBanner = () => {
  return (
    <div className="w-full h-full p-14">
      <div className="text-center">
        <h3 className="text-3xl font-bold text-blue-primary">
          Actualité & Conseils
        </h3>
        <h3 className="text-3xl font-bold text-blue-secondary">
          d'Assurance Voyage Schengen
        </h3>
        <div className="flex justify-around mt-10">
          {_.map(actuContent, (actu) => (
            <div key={actu.id}>
                <div className="flex justify-center">
                  <div className="w-3/4 h-[150px] bg-blue-primary rounded-t-3xl"/>
                </div>
              <div
                className="max-w-md h-[240px] bg-yellow-secondary border border-gray-200 rounded-tl-3xl rounded-br-3xl rounded-bl-3xl"
                key={actu.id}
              >
                <div className="p-[25px]">
                  <h5 className="mb-2 text-xl font-bold tracking-tight text-blue-primary">
                    {actu.title}
                  </h5>
                  <span className="mb-3 text-sm text-blue-secondary">
                    {actu.content}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-12 items-center">
          <div className="w-[200px] bg-yellow-secondary rounded-full h-[50px] px-5 py-2.5 text-center pt-[12px]">
            <span className="text-blue-primary uppercase h-full text-center ">
              {_.upperCase("Tous les articles")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
