import React from "react";
import { EightBanner } from "./EightBanner";
import { FirstBanner } from "./FirstBanner";
import { FiveBanner } from "./FiveBanner";
import { FourthBanner } from "./FourthBanner";
import { SecondBanner } from "./SecondBanner";
import { SevenBanner } from "./SevenBanner";
import { SixBanner } from "./SixBanner";
import { ThirdBanner } from "./ThirdBanner";
import { Navbar } from "../../../components/Navbar";
import { Footer } from "../../../components/Footer";

export const HomePage = () => {
  return (
    <>
      <Navbar />
      <FirstBanner />
      <SecondBanner />
      <ThirdBanner />
      <FourthBanner />
      <FiveBanner />
      <SixBanner />
      <SevenBanner />
      <EightBanner />
      <Footer />
    </>
  );
};
