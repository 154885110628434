import React from 'react'
import { Left } from './Left'
import { Middle } from './Middle'
import { Right } from './Right'

export const Footer = () => {
  return (
    <div className="flex justify-around w-full h-[500px] px-12 py-5 bg-grey-primary ">
      <Left />
      <Middle />
      <Right />
    </div>
  )
}
