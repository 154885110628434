import React from "react";
import styles from "./style.module.scss";

export const DocumentList = () => {
  const documentList = [
    { id: 1, name: "Document d'information normalisé" },
    { id: 2, name: "Notice d'information" },
  ];
  return (
    <div className={`${styles.bgBlock} w-full h-[285px] px-24 items-center flex items-left`}>
      <div className="grid grid-cols-2 divide-x w-[550px]">
        <div className="w-[215px]">
          <p className="text-3xl font-bold text-white">Besoin d'aide ?</p>
          <div className="text-xl font-bold text-yellow-secondary">
            <p>+33 (0)4 86 51 05 03</p>
            <p>
              Ou <span className="underline">par e-mail</span>
            </p>
            <p className="text-xs font-normal">
              Service ouvert du lundi au vendredi de 9h00 a 12h et de 14h00 à
              17h00
            </p>
          </div>
        </div>
        <div className="w-[1000px] pl-[60px]">
          <p className="text-3xl font-bold text-white">
            Des documents pratiques à votre disposition
          </p>
          <div className="text-xs underline text-yellow-secondary mt-[20px]">
            {documentList.map((document) => {
              return (
                <div key={document.id} className="flex mb-[5px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 mr-3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                    />
                  </svg>
                  <span className="">{document.name}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
