import React from "react";
import { Footer } from "../../../components/Footer";
import { Navbar } from "../../../components/Navbar";
import { DocumentList } from "./DocumentList";
import { StepsForm } from "./Form/StepsForm";
import {Banner} from "../../../components/Banner/banner";

export const DevisPage = () => {
  return (
    <>
      <Navbar />
      <Banner />
      <StepsForm />
      <DocumentList />
      <Footer />
    </>
  );
};
