import commandService from "../../../../_services/command.service";
import { Loader } from "../../../../components/Loader";
import { format } from "date-fns";
import React, { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";

const CommandListValide = (props) => {
    const { id } = props;
    const navigate = useNavigate();
    const [commands, setCommand] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        commandService.getCommandsValid(id).then(r => {
            r.data.forEach(element => {
                element.showContract = false;
                element.isOpen = false;
            });
            console.log(r.data);
            setCommand(r.data);
            setLoading(false);
        }).catch(err => {
            console.log(err);
        });
    }, [id]);

    const handleShowContract = async (id) => {
        const updatedShowContract = commands.map(element => {
            if (element.id === id) {
              return { ...element, showContract: !element.showContract, isOpen: !element.isOpen };
            }
            return element;
        });
        setCommand(updatedShowContract);
    }

    const redirectToDeclareSinistre = (id) => {
        navigate("/dashboard/sinistre/command/" + id);
    }

    return (
        <div className='px-[25px] pt-[25px] bg-[#F8F9FC] pb-[40px]'>
            <div className='flex items-center justify-between'>
                <h1 className='text-[28px] leading-[34px] font-normal text-[#5a5c69] cursor-pointer'>Dashboard</h1>
            </div>
            <div className='flex mt-[22px] w-full gap-[30px]'>
                <div className='basis-[100%] border bg-white shadow-md cursor-pointer rounded-[4px]'>
                    <div
                        className='bg-[#F8F9FC] flex items-center justify-between py-[15px] px-[20px] border-b-[1px] border-[#EDEDED] mb-[20px]'>
                        <h2 className='text-[#4e73df] text-[16px] leading-[19px] font-bold'>Selectionner un contrat</h2>
                    </div>
                    {loading ? <div className="p-12 w-full flex justify-around items-center"><Loader /></div> : <div className="w-full">
                        <div className="w-full overflow-hidden rounded-lg shadow-xs">
                            <div className="w-full overflow-x-auto">
                                <table className="w-full whitespace-no-wrap">
                                    <thead>
                                        <tr className="text-xs font-semibold tracking-wide text-left text-[#4e73df] uppercase border-b bg-white">
                                            <th className="px-4 py-3">Voir les contrats</th>
                                            <th className="px-4 py-3">Type de command</th>
                                            <th className="px-4 py-3">Nombre de contrat</th>
                                            <th className="px-4 py-3">Prix</th>
                                            <th className="px-4 py-3">Methode de paiement</th>
                                            <th className="px-4 py-3">Status</th>
                                            <th className="px-4 py-3">Date de création</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y">
                                        {commands.map(command => (
                                            <React.Fragment key={command.id}>
                                                <tr className="text-gray-700 hover:bg-stone-200" onClick={() => handleShowContract(command.id)}>
                                                    <td className="px-4 py-3">
                                                        <span className={`float-left transform ${command.isOpen ? 'rotate-0' : 'rotate-[30deg]'} transition-transform duration-300`}>
                                                            &#9660;
                                                        </span>
                                                    </td>
                                                    <td >
                                                        <div className="flex items-center text-sm">
                                                            <p className="font-semibold">{command.typeCommand}</p>
                                                        </div>
                                                    </td>
                                                    <td className="px-4 py-3 text-sm">
                                                        {command.numberContract}
                                                    </td>
                                                    <td className="px-4 py-3 text-sm">
                                                        {command.amount} €
                                                    </td>
                                                    <td className="px-6 py-3 text-md">
                                                        {command.methodPaiement}
                                                    </td>
                                                    <td className="px-4 py-3 text-xs">
                                                        <span
                                                            className={`font-semibold leading-tight ${command.status === 'Valide' ? 'text-green-700 bg-green-100 px-5 py-1' : 'text-orange-700 bg-orange-100 px-2 py-1'}  rounded-full`}>
                                                            {command.status}
                                                        </span>
                                                    </td>
                                                    <td className="px-4 py-3 text-sm">
                                                        {format(new Date(command.createdAt), "dd/MM/yyyy")}
                                                    </td>
                                                </tr>
                                                { command.showContract ? <>
                                                    <tr className="text-xs font-semibold tracking-wide text-left text-[#4e73df] uppercase border-b bg-white m-15">
                                                        <th className="px-4 py-3">
                                                        </th>
                                                        <th className="px-4 py-3">Reference</th>
                                                        <th className="px-4 py-3">Nom</th>
                                                        <th className="px-4 py-3">Prenom</th>
                                                        <th className="px-4 py-3">Création</th>
                                                        <th className="px-4 py-3">Prix de base</th>
                                                        <th className="px-4 py-3">Destination</th>
                                                    </tr>
                                                    {command.contracts.map(contract => (
                                                        <tr key={contract.id} className="text-gray-700 hover:bg-stone-200 " onClick={() => redirectToDeclareSinistre(contract.id)}>
                                                            <td className="px-4 py-3">
                                                                <span className={`float-left transform rotate-[30deg]`}>
                                                                    &#9660;
                                                                </span>
                                                            </td>
                                                            <td className="px-4 py-3">
                                                                <div className="flex items-center text-sm">
                                                                    <p className="font-semibold">{command.ref}</p>
                                                                </div>
                                                            </td>
                                                            <td className="px-4 py-3 text-sm">
                                                                {contract.lastname}
                                                            </td>
                                                            <td className="px-4 py-3 text-sm">
                                                                {contract.firstname}
                                                            </td>
                                                            <td className="px-6 py-3 text-md">
                                                                {format(new Date(contract.createdAt), "dd/MM/yyyy")}
                                                            </td>
                                                            <td className="px-4 py-3">
                                                                {contract.price} €
                                                            </td>
                                                            <td className="px-4 py-3">
                                                                {contract.destination}
                                                            </td>
                                                        </tr>
                                                    ))}</> : null }
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default CommandListValide;