import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginPage from '../Auth/LoginPage';
import RegistrationPage from '../Auth/RegistrationPage';
import ConfirmRegistrationPage from '../Auth/ConfirmRegistrationPage';
import Error from '../../_utils/Error';

const AuthRouter = () => {
    return (
        <Routes>
            <Route index element={<LoginPage/>}/>
            <Route path="register" element={<RegistrationPage/>}/>
            <Route path="confirme" element={<ConfirmRegistrationPage />} />
            <Route path="partner/login" element={<LoginPage/>}/>
            <Route path="partner/confirme" element={<ConfirmRegistrationPage />} />
            <Route path="*" element={<Error/>}/>
        </Routes>
    );
};

export default AuthRouter;