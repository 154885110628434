import React from 'react'
import {NavLink, useNavigate} from "react-router-dom";
import accountService from '../../_services/account.service';

const Sidebar = () => {
    const navigate = useNavigate();

    const logoutAction = () => {
        accountService.logout();
        navigate("/");
    }

    return (
        <div className='bg-[#4E73DF] px-[25px] h-screen bg-blue-secondary '>
            <div className='px-[15px] py-[30px] flex items-center justify-center border-b-[1px] border-[#EDEDED]/[0.3]'>
                <h1 className='text-yellow-primary text-[20px] leading-[24px] font-extrabold'>Assurance voyage schengen</h1>
            </div>
            <div className='flex items-center gap-[15px] py-[20px] border-b-[1px] border-[#EDEDED]/[0.3]'>
                <p className='text-[14px] leading-[20px] font-bold text-white'>Tableau de board</p>
            </div>
            <div className='pt-[15px] border-b-[1px] border-[#EDEDED]/[0.3]'>
                <p className='text-[10px] font-extrabold leading-[16px] text-white/[0.4]'>INTERFACE</p>

                <div className='flex items-center justify-between gap-[10px] py-[15px] cursor-pointer'>
                    <div className='flex items-center gap-[10px]'>
                        <NavLink to="./" className={({ isActive, isPending }) =>
                            isPending ? "pending" : isActive ? "active" : ""}>
                        <p className='text-[14px] leading-[20px] font-normal text-white'>Accueil</p>
                        </NavLink>
                    </div>
                </div>

                <div className='flex items-center justify-between gap-[10px] py-[15px] cursor-pointer'>
                    <div className='flex items-center gap-[10px]'>
                        <NavLink to="./commande" className={({ isActive, isPending }) =>
                            isPending ? "pending" : isActive ? "active" : ""}>
                            <p className='text-[14px] leading-[20px] font-normal text-white'>Mes Contrats</p>
                        </NavLink>
                    </div>
                </div>

                <div className='flex items-center justify-between gap-[10px] py-[15px] cursor-pointer'>
                    <div className='flex items-center gap-[10px]'>
                        <NavLink to="./sinistre" className={({ isActive, isPending }) =>
                            isPending ? "pending" : isActive ? "active" : ""}>
                            <p className='text-[14px] leading-[20px] font-normal text-white'>Mes Sinistres</p>
                        </NavLink>
                    </div>
                </div>

                <div className='flex items-center justify-between gap-[10px] py-[15px] pt-[15px] border-b-[1px] border-[#EDEDED]/[0.3] cursor-pointer'>
                    <div className='flex items-center gap-[10px]'>
                        <NavLink to="./support" className={({ isActive, isPending }) =>
                            isPending ? "pending" : isActive ? "active" : ""}>
                            <p className='text-[14px] leading-[20px] font-normal text-white'>Contacter le support</p>
                        </NavLink>
                    </div>
                </div>

                <div className='flex items-center justify-between gap-[10px] py-[15px] cursor-pointer'>
                    <div className='flex items-center gap-[10px]'>
                        <p onClick={logoutAction} className='text-[14px] leading-[20px] font-bold text-red-500'>Déconnexion</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar
