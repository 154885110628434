import React from "react";
import { FormikProvider, useFormik } from "formik";
// Components
import { Card } from "../../../../components/Card";
import { ScInput } from "../../../../components/ScInput";
import { Select } from "../../../../components/Select";
// Assets
import { ScButton } from "../../../../components/ScButton";
import {
  AppValidationSchema,
  currentDate,
  optionsCouverture,
  optionsNbPersonne,
} from "./_const";
import {useNavigate} from "react-router-dom";
import {format} from "date-fns";

export const   CardDevis = () => {
  const navigate = useNavigate();
  const onSubmitAction = ({ option, nbPersonne, startDate, endDate }) => {
    navigate("/devis?option="+ option +"&personne="+ nbPersonne + "&depart="+ startDate +"&retour="+ endDate);
  };

  const formik = useFormik({
    initialValues: {
      totalPersonne: 1,
      totalPersonneAges: 0,
      startDate: format(new Date(), "dd/MM/yyyy"),
      endDate: format(new Date(), "dd/MM/yyyy"),
      option: 0
    },
    onSubmit: onSubmitAction,
    validationSchema: AppValidationSchema,
  });

  return (
    <div className="w-[400px]">
      <Card>
        <FormikProvider value={formik}>
          <div className="flex-col items-center h-[250px]">
            <div className="flex justify-between items-center w-full mb-[30px]">
              <div className="w-[175px]">
                <Select
                  label="Type de couverture"
                  options={optionsCouverture}
                  name="option"
                  onChange={formik.handleChange}
                />
              </div>
              <div className="w-[175px]">
                <Select
                  label="Nombre de personne"
                  options={optionsNbPersonne}
                  name="nbPersonne"
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className="flex justify-between items-center w-full mb-[30px]">
              <ScInput
                name="startDate"
                placeholder={currentDate}
                value={formik.values.startDate}
                onChange={formik.handleChange}
                label="Du"
                type="date"
                position="right"
              />
              <ScInput
                name="endDate"
                placeholder={currentDate}
                value={formik.values.endDate}
                onChange={formik.handleChange}
                label="au"
                type="date"
                position="right"
              />
            </div>
            <ScButton
              type="primary"
              children="Devis en ligne"
              className="font-bold"
              onClick={formik.submitForm}
            />
          </div>
        </FormikProvider>
      </Card>
    </div>
  );
};
