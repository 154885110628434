import React, { useState }from "react";
import { Select } from "../Select";
import contractService from "../../_services/contract.service";
import { ScInput } from "../ScInput";
import { format } from "date-fns";
import { optionsListCountry } from "../../pages/Public/DevisPage/Form/_const";
import {contractValidationSchema} from "../../pages/Dashboard/Content/Contract/_const";

import "./index.scss";

export const ModalForm = ({ onCancel, closeModal, contract} ) => {
    const [ errors, setErrors] = useState({});
    const [ updateContract, setContract] = useState({
        id: contract.id,
        gender: contract.gender,
        firstname: contract.firstname,
        lastname: contract.lastname,
        nationality: contract.nationality,
        countryReside: contract.countryReside,
        born: format(new Date(contract.born), "yyyy-dd-MM"),
        speaking: contract.speaking,
        numberPass: contract.numberPass,
        expirPass: format(new Date(contract.expirPass), "yyyy-dd-MM"),
        objectifTrip: contract.objectifTrip,
        email: contract.email,
        profession: contract.profession,
        addressReside: contract.addressReside,
        dateStart: format(new Date(contract.dateStart), "yyyy-dd-MM"),
        dateEnd: format(new Date(contract.dateEnd), "yyyy-dd-MM"),
        speakCertif: contract.speak_certif,
        destination: contract.destination,
        contractType: contract.contract_type,
        fifthteenDay: (contract.moreFifteenDays !== null && contract.moreFifteenDays !== false) ? true : false,
        repaymentVisa: (contract.repaymentVisa !== null && contract.repaymentVisa !== false) ? true : false,
        responsabilityCivil: (contract.responsabilityCivil !== null && contract.responsabilityCivil !== false) ? true : false,
        noFranchise: (contract.noFranchise !== null && contract.noFranchise !== false) ? true : false,
        multiTrip: (contract.multiTrip !== null && contract.multiTrip !== false) ? true : false,
        rgpd: true,
    });

    const onChangeForm = (e) => {
        if(e.target.name === "noFranchise" || e.target.name === "multiTrip" ||
            e.target.name === "responsabilityCivil" ||e.target.name === "repaymentVisa" ||
            e.target.name === "fifthteenDay"){
            setContract(updateContract => ({
                ...updateContract,
                [e.target.name]: e.target.checked,
            }));
        }else {
            setContract(updateContract => ({
                ...updateContract,
                [e.target.name]: e.target.value,
            }));
        }
    }
    const checkForm = async () => {
        try {
            await contractValidationSchema().validate(updateContract, { abortEarly: false });
            setErrors({})
            return true;
        } catch (error) {
            const validationErrors = {};
            error.inner.forEach((err) => {
              if (err.path !== undefined) {
                validationErrors[err.path] = err.errors[0];
              }
            });
            setErrors(validationErrors);
            return false;
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        const formValid = await checkForm();
        if(formValid){
            contractService.updateContract(updateContract).then(r => {
                closeModal();
                window.location.reload();
            }).catch(err => {
                console.error(err)
            })
        }
    }
    return (
        <div className="modal-container"
             onClick={(e) => {
                 if (e.target.className === "modal-container")
                     closeModal();
             }}>
            <form className="modal" method="post">
                <div className="modal-header">
                    <p className="text-[#4e73df] text-[16px] leading-[19px] font-bold">Modifiaction du contrat</p>
                    <p onClick={() => closeModal()} className="close">&times;</p>
                </div>
                <div className="modal-content">
                    <div className="px-23 py-12  flex justify-around">
                        <div className="border-b border-gray-900/10 pb-15 border shadow rounded-lg p-5 w-4/12 ">
                            <h2 className='text-[#4e73df] text-[16px] leading-[19px] font-bold'>Informations Personnelles</h2>
                            <div className="sm:col-start-1 col-end-1">
                                <label htmlFor="gender" className="block text-sm font-medium leading-6 text-gray-900">
                                    Genre
                                </label>
                                <select name="gender"
                                    defaultValue={updateContract.gender}
                                    onChange={onChangeForm}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                    <option value="male">Monsieur</option>
                                    <option value="female">Madame</option>
                                </select>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="lastname" className="block text-sm font-medium leading-6 text-gray-900">
                                    Nom
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="lastname"
                                        id="lastname"
                                        autoComplete="given-name"
                                        defaultValue={updateContract.lastname}
                                        onChange={onChangeForm}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                {errors.lastname ? (
                                    <div className="text-red-700">{errors.lastname}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="firstname" className="block text-sm font-medium leading-6 text-gray-900">
                                    Prénom
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="firstname"
                                        id="firstname"
                                        autoComplete="given-name"
                                        defaultValue={updateContract.firstname}
                                        onChange={onChangeForm}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                {errors.firstname ? (
                                    <div className="text-red-700">{errors.firstname}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="nationality" className="block text-sm font-medium leading-6 text-gray-900">
                                    Pays de nationalité :
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="nationality"
                                        id="nationality"
                                        autoComplete="given-name"
                                        defaultValue={updateContract.nationality}
                                        onChange={onChangeForm}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                {errors.nationality ? (
                                    <div className="text-red-700">{errors.nationality}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="countryReside" className="block text-sm font-medium leading-6 text-gray-900">
                                    Pays de residence
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="countryReside"
                                        id="countryReside"
                                        autoComplete="family-name"
                                        defaultValue={updateContract.countryReside}
                                        onChange={onChangeForm}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                {errors.countryReside ? (
                                    <div className="text-red-700">{errors.countryReside}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-3">
                                <ScInput
                                    type="date"
                                    name="born"
                                    id="born"
                                    value={updateContract.born}
                                    onChange={onChangeForm}
                                    label="Date de naissance :"
                                    position="right"
                                />
                                {errors.born ? (
                                    <div className="text-red-700">{errors.born}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-3">
                                <Select
                                    label="Langue de communication :"
                                    options={optionsListCountry}
                                    value={updateContract.speaking}
                                    selectedClassName="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    name="speaking"
                                    onChange={onChangeForm}
                                />
                            </div>
                        </div>
                        <div className="border-b border-gray-900/10 pb-12 border shadow rounded-lg p-5 w-4/12">
                            <h2 className='text-[#4e73df] text-[16px] font-bold'>Informations additionnelles</h2>
                            <div className="sm:col-span-3">
                                <label htmlFor="numberPass" className="block text-sm font-medium leading-6 text-gray-900">
                                    N° passport :
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="numberPass"
                                        id="numberPass"
                                        autoComplete="family-name"
                                        defaultValue={updateContract.numberPass}
                                        onChange={onChangeForm}
                                        className="block  w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                {errors.numberPass ? (
                                    <div className="text-red-700">{errors.numberPass}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-3">
                                <ScInput
                                    type="date"
                                    name="expirPass"
                                    id="expirPass"
                                    value={updateContract.expirPass}
                                    onChange={onChangeForm}
                                    label="Date d'éxpir. passport :"
                                    position="right"
                                />
                                {errors.expirPass ? (
                                    <div className="text-red-700">{errors.expirPass}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="objectifTrip" className="block text-sm font-medium leading-6 text-gray-900">
                                    Objet de votre visite :
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="objectifTrip"
                                        id="objectifTrip"
                                        autoComplete="given-name"
                                        defaultValue={updateContract.objectifTrip}
                                        onChange={onChangeForm}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                {errors.objectifTrip ? (
                                    <div className="text-red-700">{errors.objectifTrip}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    Email :
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        autoComplete="family-name"
                                        defaultValue={updateContract.email}
                                        onChange={onChangeForm}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                {errors.email ? (
                                    <div className="text-red-700">{errors.email}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="profession" className="block text-sm font-medium leading-6 text-gray-900">
                                    Profession :
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="profession"
                                        id="profession"
                                        autoComplete="given-name"
                                        defaultValue={updateContract.profession}
                                        onChange={onChangeForm}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                {errors.profession ? (
                                    <div className="text-red-700">{errors.profession}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="addressReside" className="block text-sm font-medium leading-6 text-gray-900">
                                    Adresse de résidence (*) :
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="addressReside"
                                        id="addressReside"
                                        autoComplete="family-name"
                                        defaultValue={updateContract.addressReside}
                                        onChange={onChangeForm}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                {errors.addressReside ? (
                                    <div className="text-red-700">{errors.addressReside}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="border-b border-gray-900/10 pb-12 border shadow rounded-lg p-5 w-4/12">
                            <h2 className='text-[#4e73df] text-[16px] leading-[19px] font-bold'>Vos choix</h2>
                            <div className="sm:col-start-1 col-end-4">
                                <Select
                                    label="Langue de certification :"
                                    options={optionsListCountry}
                                    value={updateContract.speakCertif}
                                    selectedClassName="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    name="speakCertif"
                                    onChange={onChangeForm}
                                />
                            </div>
                            <div className="sm:col-start-4 col-end-8">
                                <Select
                                    label="Pays de destination :"
                                    options={optionsListCountry}
                                    value={updateContract.destination}
                                    selectedClassName="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    name="destination"
                                    onChange={onChangeForm}
                                />
                            </div>
                            <div className="sm:col-span-3">
                                <ScInput
                                    type="date"
                                    name="dateStart"
                                    id="dateStart"
                                    value={updateContract.dateStart}
                                    onChange={onChangeForm}
                                    label="Date de départ :"
                                    position="right"
                                />
                                {errors.dateStart ? (
                                    <div className="text-red-700">{errors.dateStart}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-3">
                                <ScInput
                                    type="date"
                                    name="dateEnd"
                                    id="dateEnd"
                                    value={updateContract.dateEnd}
                                    onChange={onChangeForm}
                                    label="Date de retour :"
                                    position="right"
                                />
                                {errors.dateEnd ? (
                                    <div className="text-red-700">{errors.dateEnd}</div>
                                ) : null}
                            </div>
                            <div className="sm:col-span-3">
                                <div>
                                    <label className="checkbox-input">
                                        <input
                                            id="moreFifteenDays"
                                            type="checkbox"
                                            name="moreFifteenDays"
                                            defaultChecked={updateContract.moreFifteenDays}
                                            onChange={onChangeForm}
                                        />
                                        &nbsp;+15 jours
                                    </label>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <div>
                                    <label className="checkbox-input">
                                        <input
                                            id="repaymentVisa"
                                            type="checkbox"
                                            name="repaymentVisa"
                                            defaultChecked={updateContract.repaymentVisa}
                                            onChange={onChangeForm}
                                        />
                                        &nbsp;Refus de visa
                                    </label>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <div>
                                    <label className="checkbox-input">
                                        <input
                                            id="responsabilityCivil"
                                            type="checkbox"
                                            name="responsabilityCivil"
                                            defaultChecked={updateContract.responsabilityCivil}
                                            onChange={onChangeForm}
                                        />
                                        &nbsp;Responsabilité civile
                                    </label>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <div>
                                    <label className="checkbox-input">
                                        <input
                                            id="noFranchise"
                                            type="checkbox"
                                            name="noFranchise"
                                            defaultChecked={updateContract.noFranchise}
                                            onChange={onChangeForm}
                                        />
                                        &nbsp;Sans franchise
                                    </label>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <div>
                                    <label className="checkbox-input">
                                        <input
                                            id="multiTrip"
                                            type="checkbox"
                                            name="multiTrip"
                                            defaultChecked={updateContract.multiTrip}
                                            onChange={onChangeForm}
                                        />
                                        &nbsp;Multi trip
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                <button
                        type="submit"
                        className="focus:outline-none font-medium rounded-full text-sm px-5 text-center bg-red-600 text-white"
                        onClick={() => onCancel()}
                    >
                        Annuler
                    </button>
                    <button
                        type="submit"
                        className="focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center bg-blue-secondary text-yellow-primary "
                        onClick={onSubmit}
                    >
                        Valider
                    </button>
                </div>
            </form>
        </div>
    );
}

export default ModalForm;