import sinisterService from "../../../../_services/sinister.service";
import {Link, useNavigate } from "react-router-dom";
import { Loader } from "../../../../components/Loader";
import { format } from "date-fns";
import { useState, useEffect } from "react";

const SinisterDeclared = (props) => {
    const { id } = props;
    const [sinisters, setSinister] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        sinisterService.getSinisterDeclared(id).then(r =>{
            console.log(r)
            setSinister(r.data);
            setLoading(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }, [id]);

    const redirectToShowContract = () => {

    }

    return (
        <div className='px-[25px] pt-[25px] bg-[#F8F9FC] pb-[40px]'>
            <div className='flex items-center justify-between'>
                <h1 className='text-[28px] leading-[34px] font-normal text-[#5a5c69] cursor-pointer'>Dashboard</h1>
                <Link to="./command" className='bg-[#2E59D9] h-[32px] rounded-[3px] text-white flex items-center justify-center px-[8px]'>
                    Déclarer un sinistre
                </Link>
            </div>
            <div className='flex mt-[22px] w-full gap-[30px]'>
                <div className='basis-[100%] border bg-white shadow-md cursor-pointer rounded-[4px]'>
                    <div className='bg-[#F8F9FC] flex items-center justify-between py-[15px] px-[20px] border-b-[1px] border-[#EDEDED] mb-[20px]'>
                        <h2 className='text-[#4e73df] text-[16px] leading-[19px] font-bold'>Mes sinistres déclaré </h2>
                    </div>
                    {loading ? <div className="p-12 w-full flex justify-around items-center"><Loader /></div> : <div className="w-full">
                        <div className="w-full overflow-hidden rounded-lg shadow-xs">
                            <div className="w-full overflow-x-auto">
                                <table className="w-full whitespace-no-wrap">
                                    <thead>
                                        <tr className="text-xs font-semibold tracking-wide text-left text-[#4e73df] uppercase border-b bg-white">
                                            <th className="px-4 py-3">Type de sinistre</th>
                                            <th className="px-4 py-3">Status</th>
                                            <th className="px-4 py-3">Date de création</th>
                                            <th className="px-4 py-3">Identifiant du contrat</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y">
                                        {Array.isArray(sinisters) && !sinisters.length ? (
                                            <tr className="text-s font-semibold text-center border-b bg-white">
                                                <th colSpan="6" className="px-4 py-3">Vous n'avez pas déclaré de sinistre.</th>
                                            </tr>
                                        ) : (
                                            sinisters.map(sinister => (
                                                <tr key={sinister.id} className="text-gray-700 hover:bg-stone-200" onClick={() => redirectToShowContract(sinister)}>
                                                    <td className="px-4 py-3">
                                                        <div className="flex items-center text-sm">
                                                            <p className="font-semibold">{sinister.type_claim}</p>
                                                        </div>
                                                    </td>
                                                    <td className="px-4 py-3 text-xs">
                                                        <span className={`font-semibold leading-tight ${sinister.status === 'Valide' ? 'text-green-700 bg-green-100 px-5 py-1' : 'text-orange-700 bg-orange-100 px-2 py-1'} rounded-full`}>
                                                            {sinister.status}
                                                        </span>
                                                    </td>
                                                    <td className="px-4 py-3 text-sm">
                                                        {format(new Date(sinister.created_at), "dd/MM/yyyy")}
                                                    </td>
                                                    <td className="px-4 py-3 text-sm">
                                                        {sinister.contract_id}
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default SinisterDeclared;